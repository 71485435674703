import { CreditCards } from "@components/Book/PaymentMethods/CreditCards";
import { INSPIRA_PG, STRIPE_ELEMENTS } from "@data/paymentData";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { usePaymentMethodsStore } from "@store/paymentMethodsStore";
import moment from "moment";
import React from "react";
import { shallow } from "zustand/shallow";

interface Props {
  data: SubscriptionDetailPlanV2Type[] | undefined;
  sumDeb: boolean;
}

interface LabelInfoProps {
  title: string;
  info?: string;
  infoColor?: string;
}

interface MemberStatusProps {
  idButton: string;
  status: string;
}

const MemberStatus: React.FC<MemberStatusProps> = ({ idButton, status }) => {
  idButton == "ACTIVE" ? "Active" : "Expired";
  return (
    <div className="flex-1">
      <div
        className={`tw-py-2 tw-px-4 tw-shadow-md tw-rounded-3xl tw-border-[1px] tw-border-b-inspira-gray-400 ${
          status == idButton ? "tw-bg-[#b8b9d6]" : ""
        }
        `}
      >
        <span className="tw-flex tw-items-center tw-gap-2">
          <span
            className={`${
              status == idButton
                ? "tw-content-profile-button-select-icon"
                : "tw-content-profile-button-unselect-icon"
            } tw-w-4`}
          />
          <span className="tw-text-[14px] tw-font-semibold tw-text-inspira-gray-100">
            {idButton}
          </span>
        </span>
      </div>
    </div>
  );
};

const LabelInfo: React.FC<LabelInfoProps> = ({ title, info, infoColor }) => {
  return (
    <div className="tw-mb-8">
      <h1 className="tw-text-inspira-gray-100 tw-text-[15px] tw-font-bold">
        {title}:{" "}
        {info && (
          <span
            className={`tw-font-semibold ${
              infoColor ? `${infoColor}` : "tw-text-inspira-gray-100"
            }`}
          >
            {info}
          </span>
        )}
      </h1>
    </div>
  );
};

export default function YourMembership({ data, sumDeb }: Props) {
  const dataDetail = data?.find((f) => f);
  const membershipType = dataDetail?.plan_name;
  const membershipSince = moment(dataDetail?.member_since, "YYYY-MM-DD").format(
    "MMMM DD, YYYY"
  );
  const membershipStatus = dataDetail?.plan_status || "";
  const membershipExpiry = moment(
    dataDetail?.plan_expiry_date,
    "YYYY-MM-DD"
  ).format("MMMM DD, YYYY");
  const today = moment();
  const membershipExpiryDate = moment(
    dataDetail?.plan_expiry_date,
    "YYYY-MM-DD"
  );
  const dateIsExpired = membershipExpiryDate.isBefore(today);
  const membershipBillingAmount =
    Number(dataDetail?.plan_price) +
    (sumDeb ? Number(dataDetail?.subscription_debt) : 0);

  const { getLangLabel } = useWhiteLabelLan();
  const { suppliers } = usePaymentMethodsStore(
    (state) => ({
      suppliers: state.suppliers,
    }),
    shallow
  );
  const enableStripeElements = suppliers.some((s) =>
    [STRIPE_ELEMENTS, INSPIRA_PG].includes(s.id)
  );

  if (data && data.length < 1) return <></>;

  return (
    <section className="tw-mt-8 tw-w-full tw-bg-white tw-shadow-2xl tw-rounded-2xl tw-px-4 tw-py-4 lg:tw-px-20 lg:tw-py-14">
      <div className="tw-my-8">
        <div className="tw-flex tw-flex-row tw-mt-8 tw-gap-[3rem]">
          <div className="mr-4">
            <span className={` tw-content-membership-icon tw-w-16`} />
          </div>
          <div>
            <h1 className="tw-text-[24px] tw-font-semibold tw-text-inspira-purple-500">
              {getLangLabel("YOUR MEMBERSHIP")}
            </h1>
            <h1 className="tw-mt-[0.5rem] tw-text-[15px] tw-font-semibold tw-text-inspira-gray">
              {getLangLabel(
                "INFORMATION ABOUT YOUR MEMBERSHIP; VIEW AND EDIT YOUR MEMBERSHIP STATUS, PAYMENT METHOD AND MORE"
              )}
            </h1>
          </div>
        </div>
        <div className="tw-flex tw-flex-wrap md:tw-flex-nowrap tw-flex-col md:tw-flex-row tw-mt-8 tw-gap-[2rem] tw-py-4">
          <div className="tw-flex-1">
            <LabelInfo
              title={getLangLabel("Membership type")}
              info={getLangLabel(membershipType || "")}
              infoColor="tw-text-orange-500"
            />
            <LabelInfo
              title={getLangLabel("Membership since")}
              info={membershipSince}
            />
            <LabelInfo
              title={getLangLabel("Expires")}
              info={membershipExpiry}
              infoColor={`${
                dateIsExpired ? "tw-text-red-600" : "tw-text-green-600"
              }`}
            />
            <LabelInfo title={getLangLabel("Membership Status")} />
            <div className="tw-flex tw-flex-wrap tw-gap-4">
              <MemberStatus idButton="ACTIVE" status={membershipStatus} />
              <MemberStatus idButton="EXPIRED" status={membershipStatus} />
            </div>
          </div>
          <div className="tw-flex-1">
            <LabelInfo
              title={getLangLabel("Next Billing Date")}
              info={membershipExpiry}
            />
            <LabelInfo
              title={getLangLabel("Billing Amount")}
              info={`$${membershipBillingAmount}`}
            />
            <LabelInfo title={getLangLabel("Current Payment Method")} />
            <CreditCards
              key={"select-creditcards-modal-sub"}
              ids={String("hola")}
              productType={"SUB"}
              totalAmount={0}
              extraParamsToken={""}
              show={enableStripeElements}
              isOnlyCreditCards={true}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
