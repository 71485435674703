import {
  MainContainer,
  CreditCardBrandImgContainer,
  CreditCardBrandImg,
} from "./styles";

type BrandsCreditCardsType = {
  originExecution?: "profile" | "checkOut";
};
export const BrandsCreditCards: React.FC<BrandsCreditCardsType> = ({
  originExecution,
}) => {
  const creditBrands: CreditCardBrands[] = [
    "visa",
    "mastercard",
    "amex",
    "discover",
    "dinersClub",
    "jcb",
  ];
  return (
    <MainContainer>
      {creditBrands?.map((obj: CreditCardBrands, index: number) => {
        return (
          <CreditCardBrandImgContainer key={`brandsCreditCard_${index}`}>
            <CreditCardBrandImg
              theme={{ brand: obj, originExecution: originExecution }}
            />
          </CreditCardBrandImgContainer>
        );
      })}
    </MainContainer>
  );
};
