import { create } from "zustand";

interface AdsState {
  adsData: MainCardType[];
  adsDataUsed: string[];
  adsBannerData: MainCardType[] | null;
  setAdsBannerData: (val: MainCardType[]) => void;
  setAdsData: (val: MainCardType[]) => void;
  setAdsUsedData: (val: string) => void;
  clearAdsUsedData: () => void;
  clearAdsData: () => void;
}

export const useAdsStore = create<AdsState>((set, get) => ({
  adsData: [],
  adsBannerData: null,
  adsDataUsed: [],
  setAdsData: (value: MainCardType[]) => {
    set({ adsData: value });
  },
  setAdsBannerData: (value: MainCardType[]) => {
    set({ adsBannerData: value });
  },
  setAdsUsedData: (value: string) => {
    const { adsDataUsed } = get();
    adsDataUsed.push(value);
    set({ adsDataUsed });
  },
  clearAdsUsedData: () => {
    set({ adsDataUsed: [] });
  },
  clearAdsData: () => {
    set({ adsData: [] });
  },
}));
