import { Col, Modal } from "react-bootstrap";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { ColDiv, MainContainer, Title } from "./styles";

type ModalErrorProps = {
  handleClose: () => void;
  show: boolean;
};

export const ModalError: React.FC<ModalErrorProps> = ({
  handleClose,
  show,
}) => {
  const { getLangLabel } = useWhiteLabelLan();
  return (
    <MainContainer show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Title></Title>
      </Modal.Header>
      <Modal.Body>
        <ColDiv>
          <Col>{getLangLabel("SORRY, SOMETHING WENT WRONG")}</Col>
        </ColDiv>
      </Modal.Body>
    </MainContainer>
  );
};
