import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
`;

export const CreditCardBrandImgContainer = styled.div`
  margin-right: 0.5rem;
`;

export const CreditCardBrandImg = styled.img`
  width: ${({ theme }: any) =>
    theme.originExecution == "checkOut" ? "2.2rem" : "2.2rem"};
  height: 1.5rem;

  ${({ theme }: any) =>
    () => {
      var CSSFinal = "";
      const brand: CreditCardBrands = theme.brand;

      if (brand == "visa") {
        CSSFinal += `content: url(/images/paymentMethods/IconBrandCreditCards/visa.svg);`;
      }
      if (brand == "mastercard") {
        CSSFinal += `content: url(/images/paymentMethods/IconBrandCreditCards/mastercard.svg);`;
      }
      if (brand == "amex") {
        CSSFinal += `content: url(/images/paymentMethods/IconBrandCreditCards/americanExpress.svg);`;
      }
      if (brand == "discover") {
        CSSFinal += `content: url(/images/paymentMethods/IconBrandCreditCards/discover.svg);`;
      }
      if (brand == "dinersClub") {
        CSSFinal += `content: url(/images/paymentMethods/IconBrandCreditCards/dinersClub.svg);`;
      }
      if (brand == "jcb") {
        CSSFinal += `content: url(/images/paymentMethods/IconBrandCreditCards/jcb.svg);`;
      }
      return CSSFinal;
    }};
`;
