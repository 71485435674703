import { useAuth } from "@contexts/AuthContext";
import { getProductCode } from "@helpers/helperWallet";
import { useWalletStore } from "@store/walletUserStore";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { shallow } from "zustand/shallow";

export const useGetPaymentCardByUser = () => {
  const router = useRouter();
  const { isLogged } = useAuth();

  const { fetcherGetPaymentCardByUser } = useWalletStore();
  const { isLoading, walletOptions, productCodeWallet } = useWalletStore(
    (state) => ({
      isLoading: state.isLoading,
      walletOptions: state.walletOptions,
      certificateByuser: state.certificateByuser,
      productCodeWallet: state.productCodeWallet,
    }),
    shallow
  );

  useEffect(() => {
    try {
      if (
        !(Array.isArray(walletOptions) && walletOptions.length > 0) &&
        isLogged
      ) {
        // if exist data means that api was called
        fetcherGetPaymentCardByUser(getProductCode(router.pathname));
      } else {
        if (isLogged && getProductCode(router.pathname) !== productCodeWallet) {
          fetcherGetPaymentCardByUser(getProductCode(router.pathname));
        }
      }
    } catch (error) {}

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, isLogged]);

  return { isLoading };
};
export const useGetPaymentCardByOrg = (data: PaymentCardByOrgApiReqType) => {
  const router = useRouter();

  const { fetcherGetPaymentCardByOrg } = useWalletStore();
  const { isLoading } = useWalletStore(
    (state) => ({
      isLoading: state.isLoading,
    }),
    shallow
  );
  try {
    useEffect(() => {
      try {
        fetcherGetPaymentCardByOrg(data);
      } catch (error) {}

      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query]);
  } catch (error) {}

  return { isLoading };
};

export const useDetectProductCertificates = (productCode: string) => {
  const router = useRouter();
  const { setCertificateByProduct } = useWalletStore();

  try {
    useEffect(() => {
      try {
        if (productCode) {
          setCertificateByProduct(productCode);
        }
      } catch (error) {}

      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query]);
  } catch (error) {}

  return true;
};
