import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const ColDiv = styled(Col)`
  padding: 0;
`;
export const Main = styled.main`
  /* background: rgb(245, 245, 241) none repeat scroll 0% 0%; */
  background-color: #edf2f6;
  //padding-bottom: 5rem;
  @media (max-width: 992px) {
    background-color: #fff;
  }
`;

export const RowDiv = styled(Row)`
  /* background: rgb(245, 245, 241) none repeat scroll 0% 0%; */
  background-color: #ecf1f6;
  /* padding-bottom: 5rem; */
  @media (max-width: 992px) {
    background-color: #fff;
  }
`;
export const RowHeader = styled(Row)`
  padding: 0;
  margin: 0;
`;
export const RowFooter = styled(Row)`
  /* margin-top: 2rem; */
`;
