import { Button } from "react-bootstrap";
import styled from "styled-components";

export const Btn = styled(Button)`
  background-color: #445564;
  color: #fff;
  border-radius: 2rem;
  padding: 0.5rem 1.5rem;
  :hover {
    background-color: #57599f;
    color: #fff;
  }
  :focus {
    background-color: #57599f;
    color: #fff;
  }
`;
export const BtnCustom = styled(Btn)`
  padding: 1rem 1.5rem;
`;
export const BtnComplete = styled(Button)`
  background-color: #445564;
  color: #fff;
  border-radius: 2rem;
  padding: 1rem 1.5rem;
  :hover {
    background-color: #57599f;
    color: #fff;
  }
  :focus {
    background-color: #57599f;
    color: #fff;
  }
`;
