import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const ModalCustom = styled(Modal)`
  background: rgba(64, 67, 120, 0.75);

  & .modal-dialog {
    margin-top: 5rem;
    max-width: 55rem;
  }
  & .modal-content {
    border-radius: 20px !important;
    min-height: 15rem;
  }

  @media only screen and (max-width: 992px) {
    background: #fff;
    background-clip: padding-box;
    box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);

    & .modal-content {
      height: auto; //100vh;
      border-radius: 0px;
    }

    & .modal-dialog {
      margin: 0;
      max-width: 100%;
    }
  }
`;
export const MainContainer = styled.div`
  padding: 1rem;
  @media only screen and (max-width: 992px) {
    padding: 0.5rem;
    //margin-left: -5rem;
    margin-top: 2rem;
  }
`;
export const HeaderFlex = styled.div`
  display: flex;
  justify-content: right;
`;
export const CloseModal = styled.div`
  display: flex;
  justify-content: right;
  width: 50%;
`;
export const ImgCloseModal = styled.img`
  content: url("/images/modalSignInSignUp/CloseBlue.svg");
  width: 1.2rem;
  cursor: pointer;
  @media only screen and (max-width: 992px) {
    width: 1.5rem;
  }
`;
export const IconInfoContainer = styled.div`
  display: flex;
  justify-content: left;
  width: 50%;
`;
export const IconInfo = styled.img`
  content: url(/images/walletCheckOut/infoIcon.svg);
  width: 1rem;
  height: auto;
  @media only screen and (max-width: 992px) {
    width: 1.2rem;
  }
`;

export const LabelMainInfo = styled.div`
  color: #e77c3c;
  font-size: 22px;
  font-weight: 600;
  margin-top: 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
  @media only screen and (max-width: 992px) {
    font-size: 20px;
  }
`;
export const LabelInfo = styled.div`
  color: #575757;
  font-size: 14px;
  font-weight: 600;
  margin-top: 1.5rem;
  text-align: ${({ theme }: any) => (theme.isSubTittle ? "center" : "")};
  margin-bottom: 2rem;
  @media only screen and (max-width: 992px) {
    font-size: 16px;
  }
`;

export const BtnOkContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
export const BtnOk = styled.button`
  display: ${({ theme }: any) => (theme.show ? "block" : "none")};
  background-color: #424577;
  color: #fff;
  border: 1px solid #a4a9c9;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  height: 2.8rem;
  min-width: 12rem;
  border-radius: 2rem;
  padding: 5px 10px;
  font-weight: 600;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (max-width: 992px) {
    width: 50%;
    font-size: 20px;
  }
`;
export const BtnLink = styled.a`
  display: ${({ theme }: any) => (theme.show ? "block" : "none")};
  text-decoration-line: none;
  background-color: #424577;
  color: #fff;
  border: 1px solid #a4a9c9;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  height: 2.8rem;
  min-width: 12rem;
  border-radius: 2rem;
  padding: 5px 10px;
  font-weight: 600;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
  :hover {
    color: #fff;
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
  }
  @media only screen and (max-width: 992px) {
    width: 50%;
    font-size: 20px;
  }
`;

export const ImageErrorCardContainer = styled.div`
  @media only screen and (max-width: 992px) {
    padding: 1rem;
  }
`;
export const ImageErrorCard = styled.img`
  content: url(/images/demoUserImage/demoUser.svg);
  width: 100%;
  height: 15rem;
`;
export const InfoMainContainer = styled.div`
  padding: 1rem 7.5rem 1rem 7.5rem;
  @media only screen and (max-width: 992px) {
    padding: 0rem 1rem 0rem 1rem;
  }
`;
export const ContenLabel = styled.div`
  margin-top: 0.25rem;
`;
