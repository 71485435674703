import { pagesName, productsExcluded } from "@data/config/pages";
import { checkCookies, getCookie } from "cookies-next";
import { checkIsMacOrIOS, detectBrowser } from "./helper";
//import { callSavingsProcess } from "./helperSavings";
import DailyService from "@services/daily.service";

export const getPagesEnabled = (
  eligibleProducts: ProductsApiType[],
  externalPaths: ExternalPathType[],
  all = false
) => {
  const currentLang = getCookie("lan_code");
  const isMacOrIOS = checkIsMacOrIOS();
  const browserIos = detectBrowser(["Safari"]);

  if (all) {
    const productsAllowed: any = [];
    for (const p of pagesName) {
      const external = externalPaths.find(
        (f) => f?.code?.toLowerCase() == p?.code?.toLowerCase()
      );

      if (external && external.avoidIOS && browserIos) {
        if (checkIsMacOrIOS(true)) {
          continue;
        }
      }

      if (!external || (isMacOrIOS && external.avoidIOS)) {
        if (!external?.whiteListBrowser) {
          continue;
        }

        if (
          external?.whiteListBrowser &&
          !detectBrowser(external?.whiteListBrowser)
        ) {
          continue;
        }
      }
      let label: any = external?.label ? external?.label : p.label;
      if (typeof label == "object") {
        if (
          currentLang &&
          label &&
          label?.hasOwnProperty(String(currentLang))
        ) {
          label = String(label[String(currentLang)]);
        } else if (label?.hasOwnProperty("default")) {
          label = String(label?.default);
        } else {
          label = String(p.label);
        }
      } else {
        label = String(p.label);
      }

      productsAllowed.push({
        ...p,
        path: external?.url ? external.url : p.path,
        label,
        isNewTab: external?.isNewTab ? external.isNewTab : false,
        images: {
          ...p.images,
          on: external?.images?.on ? external?.images?.on : p.images.on,
          off: external?.images?.off ? external?.images?.off : p.images.off,
        },
        order: external?.order || p.order,
        submenu: external?.submenu,
      });
    }
    // console.log("productsAllowed", productsAllowed);
    return productsAllowed;
  }

  const products = eligibleProducts.filter((f) =>
    pagesName.some((s) => s.code.toLowerCase() == f.code.toLowerCase())
  );
  const productsAllowed = products.map((p) => {
    const page = pagesName.find(
      (f) => f.code.toLowerCase() == p.code.toLowerCase()
    );
    const external = externalPaths?.find(
      (f) => f?.code?.toLowerCase() == p?.code?.toLowerCase()
    );
    return {
      ...page,
      ...p,
      path: external?.url ? external.url : page?.path,
    };
  });

  return productsAllowed;
};

export const validateParamUrl = (value: string) => {
  if (["undefined", "null", null, undefined].includes(value)) {
    return "";
  }
  return value;
};

export const getUrlToRedirect = async (
  path?: string,
  currentUser?: UserModelType,
  setSignUpShow?: (val: string) => void
) => {
  if (path?.includes("method:")) {
    // // setShowLoader && setShowLoader(true);
    // const url = await callSavingsProcess(currentUser);
    // console.info("newurl", url);
    // if (url.isNew && url.pathRedirect) {
    //   setTimeout(() => {
    //     // setShowLoader && setShowLoader(false);
    //     window.open(url.pathRedirect, "_blank");
    //   }, 15000);
    // } else if (url.pathRedirect) {
    //   // setShowLoader && setShowLoader(false);
    //   window.open(url.pathRedirect, "_blank");
    // } else {
    //   // setShowLoader && setShowLoader(false);
    // }
    const url = await DailyService.dailyMainFlow(currentUser);
    // console.log("url", url);
    if (url?.pathRedirect.includes("showSignin")) {
      // console.log("show signIn");
      setSignUpShow && setSignUpShow("signIn");
    } else {
      if (url?.pathRedirect !== "") {
        window.open(url?.pathRedirect, "_blank");
      }
    }

    return ``;
  }
  if (!path) {
    return "/";
  }
  if (path == "hotelgd") {
    return "/";
  }

  if (path == "weeks") {
    return "/resorts";
  }

  if (path == "hotel") {
    return "/";
  } else {
    if (path?.includes("http") || path?.includes("www")) {
      // window.open(path, "_blank");
      if (path?.includes("destinations.promos.network")) {
        let finalPath = `${path}?`;
        if (checkCookies("orgid")) {
          const orgId = getCookie("orgid");
          finalPath = `${finalPath}orgId=${orgId}&`;
        }
        if (currentUser?.userId) {
          const u = currentUser;
          return `${finalPath}firstName=${validateParamUrl(
            String(u?.first_Name)
          )}&lastName=${validateParamUrl(
            String(u?.last_name)
          )}&email=${validateParamUrl(
            String(u?.email)
          )}&phoneNumber=${validateParamUrl(String(u?.phoneNo))}`;
        }
      }
      return path;
    } else if (!productsExcluded.includes(path || "")) {
      return `/${path}`;
    }
  }
  return "";
};

export const checkIsActive = (path: string, pathName: string) => {
  // pathName = /holidaystays/hotel-details -> current path route
  // path = holidaystays -> depends of the config file
  if (pathName == "/home") {
    pathName = "/";
  } else {
    pathName = pathName.replace("/home", "");
  }
  if (
    path == "hotelgd" &&
    (pathName == "/" ||
      pathName == "/search-detail" ||
      pathName == "/hotel-details" ||
      pathName == "/hotel-checkout" ||
      pathName == "/checkout-success")
  ) {
    if (checkCookies("htlgd")) {
      return true;
    }
  }
  if (
    path == "hotel" &&
    (pathName == "/" ||
      pathName == "/search-detail" ||
      pathName == "/hotel-details" ||
      pathName == "/hotel-checkout" ||
      pathName == "/checkout-success")
  ) {
    if (!checkCookies("htlgd")) {
      return true;
    }
  }
  if (path == "weeks" && pathName == "/resorts") {
    return true;
  }
  if (path == "weeks" && pathName == "/resorts/search-details") {
    return true;
  }
  const splitName = pathName.split("/");
  if (splitName.length > 1) {
    return splitName[1] == path;
  }
  return false;
};

export const checkIsActiveV2 = (path: string, currentPath: string) => {
  if (path == currentPath) {
    return true;
  }
  if (path !== "/" && currentPath.includes(path)) {
    return true;
  }

  return false;
};

export const getLabelFromConfig = (
  product: extrasPathsTypeV2,
  currenLangCode: string
) => {
  let label = typeof product.label == "string" ? product.label : "";
  if (typeof product.label == "object" && currenLangCode) {
    const currentLang = getCookie("lan_code");
    if (product?.label?.hasOwnProperty(String(currentLang))) {
      label = String(product?.label[String(currentLang)]);
    } else if (product?.label?.hasOwnProperty("default")) {
      label = String(product?.label?.default);
    }
  }

  return label;
};

export const routeTranslator = (code: string) => {
  switch (code.toUpperCase()) {
    case "RST":
      return "resorts";
      break;
    case "STY":
      return "stays";
      break;
    case "HLT":
      return "";
      break;
    case "WEK":
      return "holidaystays";
      break;
    case "RST":
      return "resorts";
      break;
    case "ACT":
      return "activities";
      break;
    case "TRA":
      return "transfers";
      break;
    case "CAR":
      return "cars";
      break;
    case "FLT":
      return "flight";
      break;
  }
};

export const checkIsActiveSubmenu = (pathName: string) => {
  // pathName = /holidaystays/hotel-details -> current path route
  // path = holidaystays -> depends of the config file
  // console.log("existSub pathName", pathName);
  if (pathName == "/home") {
    return "HLT";
  } else {
    pathName = pathName.replace("/home", "");
  }
  if (
    pathName == "" ||
    pathName == "/" ||
    pathName == "/search-detail" ||
    pathName == "/hotel-details" ||
    pathName == "/hotel-checkout" ||
    pathName == "/checkout-success"
  ) {
    if (checkCookies("htlgd")) {
      return "HLT";
    }
  }
  if (
    pathName == "" ||
    pathName == "/" ||
    pathName == "/search-detail" ||
    pathName == "/hotel-details" ||
    pathName == "/hotel-checkout" ||
    pathName == "/checkout-success"
  ) {
    if (!checkCookies("htlgd")) {
      return "HLT";
    }
  }
  if (pathName == "/resorts" || pathName == "/resorts/search-details") {
    return "RST";
  }
  if (pathName == "/stays" || pathName == "/stays/search-details") {
    return "STY";
  }
  if (
    pathName == "/holidaystays" ||
    pathName == "/holidaystays/search-details"
  ) {
    return "WEK";
  }
  if (
    pathName == "/cars" ||
    pathName == "/cars/search-details" ||
    pathName == "/cars/details" ||
    pathName == "/cars/checkout" ||
    pathName == "/cars/checkout-success"
  ) {
    return "CAR";
  }
  if (
    pathName == "/flight" ||
    pathName == "/flight/search-details" ||
    pathName == "/flight/checkout" ||
    pathName == "/flight/checkout-success"
  ) {
    return "FLT";
  }
  return "";
};
