import { ADD_CARD, DYMMY_CARD, EMPTY_SELECT } from "@data/paymentData";
import {
  CardImg,
  CardImg2,
  DivCard,
  DivDefault,
  DivExpire,
  DivImg,
  DivLabel,
  DivWrap,
  DropdownMain,
  DropdownMainItem,
  DropdownMainMenu,
  DropdownToggle,
  InnerDiv,
} from "./styles";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { setDefaultCard } from "@lib/api/paymentMethods";
import { useUserProfile } from "@contexts/UserContext";
import { useState } from "react";
import { ModalError } from "./ModalError";

type DropDownCardsProps = {
  enable: boolean;
  value: string;
  options?: UserCreditCardOptionsType[];
  onChange: (optionSelected: UserCreditCardOptionsType) => void;
  updateccinfo: (optionSelected: UserCreditCardOptionsType) => void;
};

export const DropDownCards: React.FC<DropDownCardsProps> = ({
  enable,
  options,
  value,
  onChange,
  updateccinfo,
}) => {
  const { getLangLabel } = useWhiteLabelLan();
  const getOptionSelected = () => {
    return options?.find((f) => f.value == value);
  };

  const cardSelected = getOptionSelected();
  const { userProfile } = useUserProfile();
  const [showModalError, setShowModalError] = useState(false);
  const handleModalErrorClose = () => setShowModalError(false);

  const cardSelectedDefault = async (
    optionSelected: UserCreditCardOptionsType
  ) => {
    const emailId = userProfile?.basicprofile[0]?.emailId;
    const merchant_id = optionSelected.cardData?.merchant_id || 0;
    if (optionSelected) {
      const res = await setDefaultCard({
        email: emailId,
        is_default: 1,
        merchant_id: merchant_id,
      });
      if (res.status_code === 200) {
        updateccinfo(optionSelected);
      } else {
        setShowModalError(true);
      }
    } else {
      setShowModalError(true);
    }
  };
  return (
    <DropdownMain theme={{ icon: "/images/hotel-detail/checkDefault.png" }}>
      <DropdownToggle variant="light" id="dropdown-basic" disabled={!enable}>
        <CardImg src={cardSelected?.cardImage || DYMMY_CARD} />{" "}
        {cardSelected?.label}
      </DropdownToggle>

      <DropdownMainMenu>
        <InnerDiv>
          {options?.map((opt, index) => {
            const data = opt.cardData;
            const dataV2 = opt.cardDataPGV2;
            const expirationMonth = data?.expiry_month || dataV2?.exp_month;
            const expirationYear = data?.expiry_year || dataV2?.exp_year;
            const cardNumber = data?.card_number || dataV2?.last4;
            const isDefault = data?.is_default;
            const expiration =
              expirationMonth &&
              `${getLangLabel(
                "Expiration"
              )}: ${expirationMonth}/${expirationYear}`;
            const getCardNumber = (card: string) => {
              let cardnum = "";
              if (card != "") {
                cardnum = "****";
              }
              return cardnum;
            };
            let cssval = false;
            let cssdefault = false;
            let cssimgdefault = ADD_CARD;
            if (
              opt.uniquekey == "add" ||
              opt.uniquekey == "other" ||
              data?.is_default == 1
            ) {
              if (data?.is_default == 1) {
                cssdefault = true;
                cssimgdefault = "/images/hotel-detail/checkDefault.png";
              }
            }
            if (opt.uniquekey == "add" || opt.uniquekey == "other") {
              cssval = true;
            }
            return (
              <DropdownMainItem
                key={`opt-drop-menu-${index}`}
                active={value == opt.value}
                onClick={() => onChange(opt)}
                theme={{
                  icon: value == opt.value ? cssimgdefault : EMPTY_SELECT,
                  cssdefault,
                }}
              >
                <DivWrap>
                  <DivImg>
                    <CardImg2
                      src={opt?.cardImage || DYMMY_CARD}
                      theme={{ cssval: cssdefault ? !cssval : cssval }}
                    />{" "}
                  </DivImg>
                  <DivLabel theme={{ cssval }}>{opt.label} </DivLabel>
                  <DivCard>
                    {getCardNumber(cardNumber || "")} {cardNumber || ""}
                  </DivCard>
                  <DivExpire>{expiration}</DivExpire>
                  <DivDefault
                    theme={{ cssval }}
                    onClick={() => cardSelectedDefault(opt)}
                  >
                    {getLangLabel(
                      isDefault == 1 ? "DEFAULT" : "SET AS DEFAULT"
                    )}
                  </DivDefault>
                </DivWrap>
              </DropdownMainItem>
            );
          })}
        </InnerDiv>
      </DropdownMainMenu>
      <ModalError show={showModalError} handleClose={handleModalErrorClose} />
    </DropdownMain>
  );
};
