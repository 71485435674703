import { Btn } from "@components/General/Button/style";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

export const MainContainer = styled(Container)`
  background-color: #fff;
  text-align: center;
  border-radius: 15px;
  margin-top: 2rem;
  box-shadow: 0 0 10px 0 #ccc;
`;

export const TitleRow = styled(Row)`
  padding: 0rem;
  padding-top: 1rem;
`;

export const SubTitleRow = styled(Row)`
  padding: 0rem;
  padding-top: 0rem;
  position: relative;
`;
export const SubTitle = styled.span`
  text-align: left;
  padding-top: 0rem;
  padding-left: 4.5rem;
  font-size: 12px;
  font-weight: 600;
  color: #ea732d;
  position: absolute;
`;

export const Title = styled(Col)`
  text-align: left;
  padding-top: 0rem;
  padding-left: 2rem;
  font-size: 16px;
  font-weight: 800;
  color: #000;
  letter-spacing: 0.64px;
`;

export const Input = styled.input`
  border: none;
  background-color: #edeef3;
  padding: 0.375rem 1.75rem;
  ::placeholder {
    color: #868686;
    font-size: 1.2em;
    text-align: left;
    font-weight: 500;
  }
`;

export const BtnCustom = styled(Btn)`
  width: 7em;
  height: 2.5rem;
  font-weight: 500;
  z-index: 1;
  left: -1rem;
  position: absolute;
  outline: thick solid #fff;
  padding: 0%;
`;

export const ColBtn = styled(Col)`
  position: relative;
`;

export const RowBtn = styled(Row)`
  padding: 0rem;
  position: relative;
  margin-top: 2rem;
`;

export const Message = styled.span`
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  color: #45486d;
  position: absolute;
  bottom: -1.5rem;
  color: red;
`;
export const ColPaymentMethod = styled.div`
  /* @media only screen and (min-width: 1200px) {
    display: flex;
  } */

  padding: 0rem 3rem;
  padding-bottom: 1rem;
`;
export const ColMethodLabels = styled(Col)`
  /* background-image: ${({ theme }) =>
    `url(/images/wallet/${theme.imageName}.svg)`};
  background-repeat: no-repeat;
  background-position: ${({ theme }) =>
    theme.imageName == "CashCard" ? "left .5rem bottom;" : "left 1rem bottom;"};
  background-size: ${({ theme }) =>
    theme.imageName == "CashCard" ? "2.5rem" : "2rem"}; */
`;
export const ImgPaymentMethod = styled.img`
  width: ${({ theme }) => (theme.imageName == "CashCard" ? "2.5rem" : "2rem")};
  position: relative;
  top: ${({ theme }) => (theme.imageName == "CashCard" ? "1rem" : "0.5rem")};
`;
export const DivContentText = styled.div`
  display: flex;
`;
