const products = [
  { name: "hotels", code: "HLT" },
  { name: "flight", code: "FLT" },
  { name: "cars", code: "CAR" },
  { name: "activities", code: "ACT" },
  { name: "transfers", code: "TRF" },
  { name: "resorts", code: "RST" },
  { name: "stays", code: "STY" },
];

export const getProductCode = (pathname: string) => {
  const splitPath = pathname.split("/");
  console.log("splitPath", splitPath);

  let productFull = splitPath[1];
  if (productFull == "") {
    productFull = "hotels";
  }
  const found = products.find((e) => e.name == productFull);

  if (found) {
    return found.code;
  }

  return "";
};
