import { useWhiteLabelLan } from "@hooks/useLayout";
import {
  ModalCustom,
  MainContainer,
  CloseModal,
  ImgCloseModal,
  LabelInfo,
  BtnOkContainer,
  BtnOk,
  HeaderFlex,
  LabelMainInfo,
  ImageErrorCardContainer,
  ImageErrorCard,
} from "./styles";

type ModalStripeElementsProccessCardFailType = {
  show: boolean;
  isMobile: boolean;
  handleCloseModal: (state: boolean) => void;
  tittle?: string;
  subtittle?: string;
};
export const ModalStripeElementsProccessCardFail: React.FC<
  ModalStripeElementsProccessCardFailType
> = ({ show, isMobile, handleCloseModal, tittle, subtittle }) => {
  const { getLangLabel } = useWhiteLabelLan();
  return (
    <ModalCustom
      show={show}
      fullscreen={isMobile}
      onHide={() => {
        if (!isMobile) {
          handleCloseModal(false);
        }
      }}
    >
      <MainContainer>
        <HeaderFlex>
          <CloseModal>
            <ImgCloseModal
              onClick={() => {
                handleCloseModal(false);
              }}
            />
          </CloseModal>
        </HeaderFlex>

        <LabelMainInfo>{`${getLangLabel(
          tittle ? tittle.toUpperCase() : "SORRY! UNABLE TO PROCESS YOUR CARD"
        )}`}</LabelMainInfo>
        <LabelInfo
          theme={{ isSubTittle: subtittle ? true : false }}
        >{`${getLangLabel(
          subtittle
            ? subtittle.toUpperCase()
            : "WE COULDN'T PROCESS PAYMENT ON YOUR CURRENT CARD, PLEASE USE A DIFFERENT ON AND TRY AGAIN"
        )}.`}</LabelInfo>
        <ImageErrorCardContainer>
          <ImageErrorCard />
        </ImageErrorCardContainer>
        <BtnOkContainer>
          <BtnOk
            className="continue-button"
            onClick={() => {
              handleCloseModal(false);
            }}
          >{`${getLangLabel("OK")}`}</BtnOk>
        </BtnOkContainer>
      </MainContainer>
    </ModalCustom>
  );
};
