import axios from "axios";
import { LocalOverrides } from "./localOverride";

export const ApplyCouponCode = async (json: {
  coupon_code: string;
  discount_amount: number;
  discount_currency: string;
  product_type: string;
  org_id?: number;
  coupon_id?: number;
  coupon_trn_id?: string;
  isMathch?: boolean;
}): Promise<ApplyCouponCodeResponseType | null> => {
  try {
    json = LocalOverrides.attachBypassInfo(json);
    const response = await axios.post(`/api/general/applyCouponCodeDetails`, {
      json,
    });
    return response?.data?.data?.data;
  } catch (error) {
    console.info(error);
    return null;
  }
};
