import { Modal, Row } from "react-bootstrap";
import styled from "styled-components";

export const MainContainer = styled(Modal)`
  border-radius: 10px;
  background: rgba(64, 67, 120, 0.75);
`;

export const Title = styled(Modal.Title)`
  font-weight: 600;
  font-size: 1.2rem;
  color: #424577;
`;
export const ColDiv = styled(Row)`
  margin-bottom: 10px;
  color: #424577;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
`;
