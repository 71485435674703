import axios from "axios";

export const getTermsOfUseMembershipUpgrade =
  async (): Promise<UpgradeMembershipTermsResponseType> => {
    const response = await axios.post(`/api/membershipUpgrade/terms-of-use`, {
      lang: "en-US",
    });
    return response.data;
  };

export const getDatasubscriptionplanss = async (): Promise<
  MembershipType[]
> => {
  try {
    const response = await axios.post(
      `/api/membershipUpgrade/subscription-plans`,
      {
        lang: "en-US",
      }
    );
    const data = response.data.subscriptionplans.data.data.data;
    if (Array.isArray(data)) {
      return data;
    }
  } catch (error) {
    return [];
  }
  return [];
};

export const getDatasubscriptionplanssType =
  async (): Promise<MembershipReposeType> => {
    const response = await axios.post(
      `/api/membershipUpgrade/subscription-plans`,
      {
        lang: "en-US",
      }
    );
    return response.data.subscriptionplans.data.data;
  };

export const getInitBookPayment = async ({
  sub_plan_id,
  currency,
  subscription_code,
  membership_id,
  plan_amount,
  userId,
  referer,
  charge_org,
}: MembershipPaymentResponseType): Promise<any> => {
  try {
    const response = await axios.post(
      `/api/membershipUpgrade/payment-membership`,
      {
        sub_plan_id,
        currency,
        subscription_code,
        membership_id,
        plan_amount,
        userId,
        referer,
        charge_org,
      }
    );
    return response.data;
  } catch (error) {
    console.info(error);

    return null;
  }
};
