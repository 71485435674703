import axios from "axios";

export const getCouponCodeDetails = async (
  product_type: string,
  currency?: string
): Promise<CarCoupponCodeType[] | null> => {
  try {
    const response = await axios.post(`/api/general/getCouponCodeDetails`, {
      product_type,
      currency,
    });
    return response?.data?.data?.data;
  } catch (error) {
    console.info(error);
    return null;
  }
};
