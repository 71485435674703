import { useEffect } from "react";
import { usePaymentMethodsStore } from "@store/paymentMethodsStore";
import { useAuth } from "@contexts/AuthContext";
import { shallow } from "zustand/shallow";

export const usePaymentMethods = () => {
  const { fetchSupplierPG } = usePaymentMethodsStore();

  try {
    useEffect(() => {
      fetchSupplierPG();
      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  } catch (error) {}

  return true;
};

export const useGetUserCreditCards = () => {
  const { currentUser } = useAuth();
  const { fetchGetUserCreditCards } = usePaymentMethodsStore();
  const { isEnabledPGV2 } = usePaymentMethodsStore(
    (state) => ({
      isEnabledPGV2: state.isEnabledPGV2,
    }),
    shallow
  );

  try {
    useEffect(() => {
      if (currentUser?.email && !isEnabledPGV2) {
        fetchGetUserCreditCards(currentUser?.email);
      }
      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, isEnabledPGV2]);
  } catch (error) {}

  return true;
};
