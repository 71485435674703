import { PATH_PG_SWITH } from "@helpers/config";
import { classNames } from "@helpers/helper";
import { paymentMethodsPgV2Store } from "@store/paymentMethodsPgV2Store";
import { usePaymentMethodsStore } from "@store/paymentMethodsStore";
import axios from "axios";
import { shallow } from "zustand/shallow";

/* eslint-disable @next/next/no-img-element */
type Props = {
  cardId: string;
  open: boolean;
  onClose: () => void;
};
export const DeleteCreditCard = ({ cardId, open, onClose }: Props) => {
  const { customer } = paymentMethodsPgV2Store(
    (state) => ({
      customer: state.customer,
    }),
    shallow
  );
  const { apiKeySupplier } = usePaymentMethodsStore(
    (state) => ({
      apiKeySupplier: state.apiKeySupplier,
    }),
    shallow
  );
  const { fetchLoadCards } = paymentMethodsPgV2Store();
  const deleteCard = async (user: string, id: string) => {
    try {
      const res = await axios.delete(
        `${PATH_PG_SWITH}/v1/card/delete/${user}`,
        {
          data: {
            card_id: id,
          },
          headers: {
            "Content-Type": "application/json",
            "api-key": `${apiKeySupplier}`,
          },
        }
      );

      return res.data;
    } catch (error) {
      console.log("🚀 ~ deleteCard ~ error:", error);

      return null;
    }
  };
  const onSubmit = () => {
    if (cardId) {
      // call the api to update the card
      deleteCard(customer?.email || "", cardId).then(() => {
        fetchLoadCards();
      });
    }
    onClose();
  };

  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabIndex={-1}
      aria-hidden="true"
      className={classNames(
        "tw-overflow-y-auto tw-bg-[var(--var-inspira--bk-purple)] tw-overflow-x-hidden tw-fixed tw-top-0 tw-right-0 tw-left-0 tw-z-50 tw-flex tw-justify-center tw-items-center tw-align-middle tw-w-full md:tw-inset-0 tw-h-[calc(100%)] tw-max-h-full",
        open ? "" : "tw-hidden"
      )}
    >
      <div className="tw-relative tw-p-4 tw-w-full tw-max-w-xl tw-max-h-full">
        <div className=" tw-relative tw-bg-white tw-rounded-lg tw-shadow">
          <div className=" tw-flex tw-items-center tw-justify-between tw-p-4 md:tw-p-5 tw-border-b tw-rounded-t">
            <h3 className=" tw-text-xl tw-font-semibold tw-text-[#424577]">
              Delete Credit Card
            </h3>
            <button
              type="button"
              className=" tw-absolute tw-right-0 tw-top-0 tw-text-gray-400 tw-bg-transparent hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-rounded-lg tw-text-sm tw-w-8 tw-h-8 tw-ms-auto tw-inline-flex tw-justify-center tw-items-center "
              data-modal-hide="static-modal"
              onClick={onClose}
            >
              <img src="/images/general/close-bgWhite.svg" alt="close" />
              <span className="tw-sr-only">Close modal</span>
            </button>
          </div>
          <div className="tw-p-4 tw-md:p-5 tw-space-y-4">
            <div className="tw-space-y-1 tw-flex tw-flex-col tw-justify-start tw-text-left">
              <p className="tw-text-base tw-text-gray-700 tw-font-semibold">
                Are you sure you want to delete this card?
              </p>
            </div>
            <div className="tw-flex tw-items-center tw-py-4 tw-border-t tw-border-gray-200 tw-rounded-b ">
              <button
                type="button"
                className={classNames(
                  "tw-text-[#424577] tw-font-medium tw-text-sm hover:tw-text-gray-900 tw-rounded-full tw-py-2 tw-px-4 hover:tw-bg-slate-100 tw-bg-white tw-border tw-border-gray-300 tw-mr-2"
                )}
                data-modal-hide="static-modal"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={onSubmit}
                className={classNames(
                  "tw-ml-auto tw-bg-red-500 tw-text-white tw-font-medium tw-text-sm tw-rounded-full tw-py-2 tw-px-4 hover:tw-bg-red-600 hover:tw-text-white  tw-shadow tw-border tw-border-gray-300"
                )}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
