export class LocalOverrides {

  private static getBypassOverride():any{
    let bypassOverride = localStorage.getItem('bypassOverride');
    if(!(!!bypassOverride)) bypassOverride = "{}";
    bypassOverride = bypassOverride.replace(/'/g, '"');
    let override:any =  Object.assign({bypass:false,bypassIds:[]},JSON.parse(bypassOverride)); 
    return {bypass:override.bypass,bypassIds:override.bypassIds};

  }

  public static attachBypassInfo(reqBody:any){
    if(!(!!reqBody)) reqBody = {};
    reqBody.bypassOverride = this.getBypassOverride();
    return reqBody;
  }

};