import { useAdsStore } from "@store/adsStore";
import { useEffect, useState } from "react";
import { adsParallelCalls, getCurrentSection } from "@services/ads.service";
import { useRouter } from "next/router";
import { useLayoutStore } from "@store/layoutStore";
import { shallow } from "zustand/shallow";
import adsConfig from "@data/pagesConfigAds.json";
import { ORG_ID } from "@helpers/config";
import { useAuth } from "@contexts/AuthContext";
import { getAdsBannerRespApi } from "@lib/api/ads/getAdsBannerRespApi";
import { checkCookies, getCookie } from "cookies-next";
import {
  MATCH_ANGLE_BRACKETS_TO_REMOVE_KEY,
  getDateByProduct,
  // MATCH_ANGLE_BRACKETS_TO_REMOVE_URL,
  // MATCH_SLACH_AMPERSON,
  getTodayDate,
  pathDictionary,
} from "@helpers/helperAds";
import { useWindowSize } from "./useWindowSize";
import { getUserValFromKey } from "@helpers/helper";
import { validateParamUrl } from "@helpers/pages";
const home = [
  "/",
  "/holidaystays",
  "/cruises",
  "/cars",
  "/flight",
  "/transfers",
  "/activities",
  "/weeks",
  "/resorts",
  "/stays",
];

export const useCreateAdsBannerRequest = (
  section: string
): AdsResponseType | null => {
  const router = useRouter();
  const { setAdsBannerData } = useAdsStore();
  const size = useWindowSize();

  const { config, isLoadedExternalConfig, showAdsBannerOnMobile } =
    useLayoutStore(
      (state) => ({
        config: state.config,
        enablePreloginAds: state.externalConfig?.enablePreloginAds,
        isLoadedExternalConfig: state.isLoadedExternalConfig,
        showAdsBannerOnMobile: state.externalConfig?.showAdsBannerOnMobile,
      }),
      shallow
    );
  const orgId = config?.orgBySite?.orgId || ORG_ID || null;
  const pathName = router.pathname;

  try {
    useEffect(() => {
      if (isLoadedExternalConfig) {
        let runOnMobile = size.width >= 992; // true is not mobile

        if (size.width === 0) {
          let isIos = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
          if (isIos) {
            if (typeof window !== "undefined") {
              let iosSize = window.innerWidth;
              runOnMobile = iosSize >= 992;
            }
          }
        }

        // if (showAdsBannerOnMobile) {
        // alert("showAdsBannerOnMovile");
        console.log("showAdsBannerOnMobile", showAdsBannerOnMobile);
        runOnMobile = true;
        // }

        if (runOnMobile) {
          if (orgId) {
            if (home.includes(pathName)) {
              getAdsBannerRespApi({
                pathName: pathName,
                orgId: orgId,
                section: section,
              }).then((config) => {
                setAdsBannerData(config);
              });
            }
          }
        }
      }

      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query, orgId, isLoadedExternalConfig]);
  } catch (error) {}

  return null;
};

export const useCreadAdsRequest = (): ConfigApiType | null => {
  const { isLogged } = useAuth();
  const router = useRouter();

  const [enableAds, setEnableAds] = useState(false);
  const [orgId, setOrgId] = useState<number | null>(null);
  const { configOrgId, enablePreloginAds, adsCheckinDate } = useLayoutStore(
    (state) => ({
      config: state.config,
      configOrgId: state.config?.orgBySite?.orgId,
      enablePreloginAds: state.externalConfig?.enablePreloginAds,
      isLoadedExternalConfig: state.isLoadedExternalConfig,
      adsCheckinDate: state.adsCheckinDate,
      showAdsOnMobile: state.externalConfig?.showAdsOnMobile,
    }),
    shallow
  );

  const { setAdsData, clearAdsUsedData, clearAdsData } = useAdsStore();

  useEffect(() => {
    if (configOrgId) {
      setOrgId(configOrgId);
    } else if (ORG_ID) {
      setOrgId(ORG_ID);
    } else {
      setOrgId(null);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configOrgId]);

  useEffect(() => {
    if (!!enablePreloginAds) {
      if (!isLogged) {
        setEnableAds(false);
        return;
      }
    } else if (["/resorts", "/stays"].includes(router.pathname)) {
      if (!isLogged) {
        setEnableAds(false);
        return;
      }
    } else if (isLogged) {
      setEnableAds(false);
    }

    setEnableAds(true);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enablePreloginAds, isLogged]);

  useEffect(() => {
    if (enableAds) {
      if (orgId) {
        console.log("enablePreloginAds seDispraran", router.pathname);
        const pathName = router.pathname;

        const checkinDate = adsCheckinDate
          ? adsCheckinDate
          : checkCookies("adsCheckinDate")
          ? String(getCookie("adsCheckinDate"))
          : getTodayDate();

        clearAdsUsedData();
        clearAdsData();
        adsParallelCalls(pathName, orgId, String(checkinDate)).then(
          (groupConfig) => {
            setAdsData(groupConfig);
          }
        );
      }
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, orgId, enableAds]);

  return null;
};

export const useAdsToShow = (section?: string, space?: string) => {
  const { adsData, adsDataUsed } = useAdsStore(
    (state) => ({
      adsData: state.adsData,
      adsDataUsed: state.adsDataUsed,
    }),
    shallow
  );
  const { setAdsUsedData } = useAdsStore();
  const [data, setData] = useState<MainCardType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const route = useRouter();

  const getData = (productType: string, spaceName: string) => {
    try {
      const dataCard = [] as MainCardType[];
      let pathName = route.pathname;
      pathName = pathName == "/home" ? pathName : pathName.replace("/home", "");
      const currentSection = getCurrentSection(pathName);
      const productConfig = adsConfig.find((f) => f.product == productType);
      const sectionConfig = productConfig?.sections?.find(
        (f) => f.name == currentSection
      );
      const spaceConfig = sectionConfig?.spaces.find(
        (f) => f.name == spaceName
      );
      console.log(
        "🚀 ~ file: useAds.tsx:65 ~ getData ~ spaceConfig:",
        currentSection, //cars
        spaceConfig,
        spaceName,
        productConfig
      );

      if (!spaceConfig) {
        return [];
      }
      console.log("🚀 ~ file: useAds.tsx:76 ~ getData ~ adsData:", adsData);
      spaceConfig.config.map((config) => {
        const priority = config.priority;
        const typeStr = config.type.join("_");
        const cardsData = adsData.filter(
          (f) => f.petitionTypeStr == typeStr && f.priority == priority
        );
        cardsData.some((value) => {
          const cardIsUsed = adsDataUsed.some((s) => s == value.uuid);
          const cardIsUsedData = dataCard.some((s) => s.uuid == value.uuid);
          if (!cardIsUsed && !cardIsUsedData) {
            setAdsUsedData(String(value.uuid));
            dataCard.push(value);
            return true;
          }
          return false;
        });
      });

      console.log(
        "🚀 ~ file: index.tsx:77 ~ getData ~ dataCard:",
        dataCard,
        adsDataUsed
      );
      setIsLoading(false);
      return dataCard;
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    return [];
  };

  try {
    useEffect(() => {
      if (adsData && adsData.length > 0 && section && space) {
        setIsLoading(true);
        const data = getData(section, space);
        setData(data);
      } else {
        setIsLoading(false);
        if (adsData && adsData.length == 0) {
          setData([]);
        }
      }

      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adsData, section, space]);
  } catch (error) {}

  return { data, isLoading };
};

export const useDinamicUrl = () => {
  const router = useRouter();
  const query = router.query;
  let path = pathDictionary(router.pathname);
  const { config } = useLayoutStore(
    (state) => ({
      config: state.config,
    }),
    shallow
  );
  const orgId = config?.orgBySite?.orgId || ORG_ID || null;

  const getUserInfoByKey = (
    urlRedirect: string,
    isFilledUrl: string[] | null,
    currentUser: UserModelType
  ) => {
    let tmpUrl = urlRedirect;
    let dateKey = ["checkin", "checkout"];
    // let newUrlRemoveSpecialVars = `${urlRedirect.replace(
    //   MATCH_ANGLE_BRACKETS_TO_REMOVE_URL,
    //   ""
    // )}?`;

    // let newUrl = newUrlRemoveSpecialVars.replace(MATCH_SLACH_AMPERSON, "?");
    // let counter = 0;

    console.log("itter new");
    isFilledUrl?.map((f: string) => {
      const key = f.replace(MATCH_ANGLE_BRACKETS_TO_REMOVE_KEY, "");
      let value = "";
      if (dateKey.includes(key)) {
        console.log("iterUrl key", key, path);
        value = getDateByProduct(path, query, key);
      } else {
        value = getUserValFromKey(key, currentUser);
      }

      // counter == 0 ? (newUrl += "") : (newUrl += "&");
      // newUrl += `${key}=${value}`;
      tmpUrl = tmpUrl.replace(new RegExp(f, "g"), validateParamUrl(value));
      // counter++;
    });

    return `${tmpUrl}&orgId=${orgId}`;
  };

  return { getUserInfoByKey };
};
