import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";

export const ModalContainer = styled(Modal)`
  background: rgba(64, 67, 120, 0.75);
  & .modal-header {
    border-bottom: 0;
  }
  & .modal-content {
    border-radius: 20px;
    background-color: #ecf1f5;
    text-align: center;
  }
  & .modal-dialog {
  }
`;

export const ButtonClose = styled(Button)`
  margin: 2rem auto;
  background-color: var(--var-inspira-purple);
  border-radius: 25px;
  color: #fff;
  height: 3rem;
  width: 150px;
`;

export const H1 = styled.h1`
  font-size: 1rem;
  padding-top: 1rem;
  color: ${({ theme }) => (theme.isOK ? "var(--bs-green)" : "var(--bs-red)")};
`;
