/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { useWhiteLabelLan } from "@hooks/useLayout";
import { classNames } from "@helpers/helper";
import { IMAGE_PATH } from "@helpers/config";
import { useEffect, useState } from "react";
import { getCss } from "@lib/api/general/getCss";
import { useAuth } from "@contexts/AuthContext";
import { useRouter } from "next/router";

type Props = {
  data: DataPlansV2Type | null;
  setInitBookData: (value: InitBookSubsPlanType) => void;
  scrollPayment: () => void;
};
const PlansList = ({ data, setInitBookData, scrollPayment }: Props) => {
  const { getLangLabel } = useWhiteLabelLan();
  const [planTermSelected, setPlanTermSelected] = useState("");
  const [planTermSelectedHtml, setPlanTermSelectedHtml] = useState("");
  const [selectedPlan, setSelectedPlan] = useState<number | null>(null);
  const [imgError, setImgError] = useState<number[]>([]);

  const { currentUser, isLogged } = useAuth();
  const router = useRouter();
  const plans = data?.plans || [];

  const PER_YEAR_TRADUCTION = () => {
    return `${getLangLabel("YEAR")}`;
  };
  const PER_MONTH_TRADUCTION = () => {
    return `${getLangLabel("MONTH")}`;
  };
  const GET_PERIOD_MEMBERSHIP_TRADUCTION = (
    count: number,
    type: "month" | "year"
  ) => {
    let label: string = ``;
    if (type == "year" && count > 0) {
      if (count == 1) {
        label = PER_YEAR_TRADUCTION();
      } else {
        label = `${count} ${getLangLabel("YEARS")}`;
      }
    } else if (type == "month" && count > 0) {
      if (count == 1) {
        label = PER_MONTH_TRADUCTION();
      } else {
        label = `${count} ${getLangLabel("MONTHS")}`;
      }
    }
    return label;
  };
  const GET_FORMAT_PRICE = (price: number, currencyCode: string) => {
    return `${price} ${currencyCode}`;
  };

  const getLabelFromYear = (value: string) => {
    const year = Number(value);
    if (year === 1) {
      return `${PER_YEAR_TRADUCTION()} ${value}`;
    } else if (year > 1) {
      return `${year} ${getLangLabel("YEARS")}`;
    }
  };

  const getLabelFromMonth = (value: string) => {
    const month = Number(value);
    if (month === 1) {
      return `${PER_MONTH_TRADUCTION()} ${value}`;
    } else if (month > 1) {
      return `${month} ${getLangLabel("months")}`;
    }
  };

  const redirectToPurchase = (data: PlanV2Type) => {
    const currency_user = data.currency_code;
    //setshowCreditCardPayment(true);
    //let referer = window.location.href;

    if (isLogged) {
      let data1: InitBookSubsPlanType = {
        sub_plan_id: data.sub_id,
        currency: currency_user, //this.props.signInData.result.currency_code,
        subscription_code: String(data.subplan_name),
        membership_id: Number(data.membership_id),
        plan_amount: Number(data.sub_plan_initial_price),
        userId: String(currentUser?.userId),
        referer: router.asPath,
        plan_charge_after: data.sub_plan_charge_after,
        charge_org: !!data.init_charge_org,
      };

      console.log(data, "plan select =====>");
      setInitBookData(data1);
    }
    setTimeout(() => {
      scrollPayment();
    }, 700);
  };

  useEffect(() => {
    if (!planTermSelected && plans?.length > 0) {
      setPlanTermSelected(plans[0].html);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (planTermSelected) {
      getCss(planTermSelected).then((res) => {
        setPlanTermSelectedHtml(res);
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planTermSelected]);

  return (
    <div className=" tw-mt-6 tw-w-full tw-flex tw-flex-row tw-justify-center tw-gap-1 tw-flex-wrap tw-items-center tw-align-middle tw-overflow-hidden tw-overflow-x-auto">
      {plans.map((plan, index) => {
        const sub_plan_auto_validity_year: number = Number(
          plan.sub_plan_auto_validity_year || 0
        );
        const sub_plan_auto_validity_month: number = Number(
          plan.sub_plan_auto_validity_month || 0
        );

        return (
          <article
            key={`plan-item-${index}`}
            className={classNames(
              "tw-min-w-60 tw-h-96 tw-m-4 tw-py-4 tw-px-2 tw-border tw-rounded-xl tw-border-gray-200 tw-shadow-4xl  tw-text-center tw-flex tw-flex-col tw-justify-between tw-items-center tw-bg-white"
            )}
          >
            <h1 className="tw-text-2xl tw-pb-2 tw-font-bold tw-text-gray-800 tw-border-b  tw-w-full ">
              {getLangLabel(plan?.subplan_name || "")}
            </h1>
            <img
              src={
                !imgError.includes(plan.sub_id)
                  ? `${IMAGE_PATH}${plan?.sub_plan_image_url}`
                  : "/images/notFound/NoImageMemeber.svg"
              }
              className="tw-h-20 tw-mt-4"
              alt="Plan"
              onError={() => {
                let tmp = [...imgError];
                tmp.push(plan.sub_id);
                setImgError(tmp);
              }}
            />
            <p className="tw-pt-4 tw-text-gray-900 tw-font-semibold tw-text-xs tw-text-center tw-max-w-60 tw-overflow-hidden tw-text-ellipsis tw-line-clamp-2 tw-mb-[0rem]">
              {getLangLabel(plan?.subplan_desc || "")}
            </p>
            <div className={`tw-flex plan-desc-1`}>
              <div className="tw-text-3xl tw-font-bold tw-text-gray-800 tw-m-0">
                {`${GET_FORMAT_PRICE(
                  Number(plan?.sub_plan_initial_price || 0),
                  String(plan?.currency_code || "USD")
                )}`}
              </div>
              <div className=" tw-text-md tw-font-bold tw-ml-[0.5rem] tw-mt-[0.6rem]">
                {getLabelFromYear(plan.sub_plan_validity_year || "0")}{" "}
                {getLabelFromMonth(plan.sub_plan_validity_month || "0")}
              </div>
            </div>

            {plan.is_renewable && (
              <span className="tw-text-xs tw-font-bold plan-desc-2">
                <span className="renewal-tag">{`${getLangLabel(
                  "RENEWAL"
                )}`}</span>
                <span className="span1">
                  {` ${GET_FORMAT_PRICE(
                    Number(plan.sub_plan_renewal_price || 0),
                    String(plan?.currency_code || "USD")
                  )} `}
                </span>
                <span className="slash-text">/&nbsp;</span>
                <span className="span2">
                  {`${GET_PERIOD_MEMBERSHIP_TRADUCTION(
                    sub_plan_auto_validity_year
                      ? sub_plan_auto_validity_year
                      : sub_plan_auto_validity_month,
                    sub_plan_auto_validity_year ? "year" : "month"
                  )}`}
                </span>
              </span>
            )}
            <button
              type="button"
              onClick={() => {
                redirectToPurchase(plan);
                setPlanTermSelected(plan.html);
                setSelectedPlan(plan.sub_id);
              }}
              className={classNames(
                `buttonPurchase-${index} tw-relative tw-text-white tw-py-2 tw-px-4 tw-mt-4 tw-w-full tw-text-xl tw-rounded-full tw-text-center tw-max-w-48 hover:tw-opacity-60 hover:tw-ring-2`,
                selectedPlan == plan.sub_id
                  ? "tw-bg-[#424577] tw-content-profile-button-select-icon"
                  : "tw-bg-black tw-content-profile-button-unselect-icon "
              )}
            >
              {" "}
              <span
                className={`${
                  selectedPlan == plan.sub_id
                    ? "tw-absolute  tw-content-profile-button-select-icon tw-top-[0.6rem] tw-right-6"
                    : ""
                } tw-w-6`}
              />
              {Number(plan.renew_charge_org) > 0
                ? getLangLabel("Trial")
                : plan.is_renewable
                ? getLangLabel("Subscribe")
                : getLangLabel("Purchase")}
            </button>
          </article>
        );
      })}
      <section className=" tw-mt-4 tw-w-full tw-flex ">
        {planTermSelectedHtml !== "FILE_NOT_FOUND" && (
          <div dangerouslySetInnerHTML={{ __html: planTermSelectedHtml }}></div>
        )}
      </section>
    </div>
  );
};

export default PlansList;
