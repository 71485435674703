import axios from "axios";

export const unlockCouponCode = async (obj: UnlockCouponCodeType) => {
  const response = await axios.post("/api/booking/unlockCouponCodeApi", {
    ...obj,
  });
  console.log("/api/booking/unlockCouponCodeApi", {
    response: response,
  });
  return response.data;
};
