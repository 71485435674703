import { useWhiteLabelLan } from "@hooks/useLayout";
import { useBreakpoints } from "@hooks/useBreakpoints";
import { shallow } from "zustand/shallow";
import { useLayoutStore } from "@store/layoutStore";
import {
  ModalCustom,
  MainContainer,
  CloseModal,
  ImgCloseModal,
  LabelInfo,
  BtnOkContainer,
  BtnOk,
  HeaderFlex,
  LabelMainInfo,
  ImageErrorCardContainer,
  ImageErrorCard,
  InfoMainContainer,
  BtnLink,
  ContenLabel,
} from "./styles";

type ModalUserDemoModeType = {
  show: boolean;
  handleCloseModal: (state: boolean) => void;
  tittle: string;
  subtittle: string;
};
export const ModalUserDemoMode: React.FC<ModalUserDemoModeType> = ({
  show,
  handleCloseModal,
  tittle,
  subtittle,
}) => {
  const { breakpoints } = useBreakpoints();
  const { getLangLabel } = useWhiteLabelLan();
  const { urlNoBook, demoModalUrlRedirect } = useLayoutStore(
    (state) => ({
      urlNoBook: state.externalConfig?.urlNoBook || "",
      demoModalUrlRedirect: state.externalConfig?.demoModalUrlRedirect || "",
    }),
    shallow
  );
  return (
    <ModalCustom
      show={show}
      fullscreen={breakpoints.down("lg")}
      onHide={() => {
        if (!breakpoints.down("lg")) {
          handleCloseModal(false);
        }
      }}
    >
      <MainContainer>
        <HeaderFlex>
          <CloseModal>
            <ImgCloseModal
              onClick={() => {
                handleCloseModal(false);
              }}
            />
          </CloseModal>
        </HeaderFlex>
        <InfoMainContainer>
          <LabelMainInfo>{`${getLangLabel(
            tittle.toUpperCase()
          )}`}</LabelMainInfo>
          <ImageErrorCardContainer>
            <ImageErrorCard />
          </ImageErrorCardContainer>
          <LabelInfo
            theme={{ isSubTittle: subtittle ? true : false }}
          >{`${getLangLabel(subtittle.toUpperCase())}`}</LabelInfo>
          <BtnOkContainer>
            <BtnOk
              className="continue-button"
              theme={{ show: !urlNoBook }}
              onClick={() => {
                if (demoModalUrlRedirect && demoModalUrlRedirect != "") {
                  window.location.replace(`${demoModalUrlRedirect}`);
                  return;
                }
                handleCloseModal(false);
              }}
            >{`${getLangLabel("OK")}`}</BtnOk>
            <BtnLink
              target="_blank"
              href={urlNoBook}
              theme={{ show: urlNoBook }}
              onClick={() => {
                if (demoModalUrlRedirect && demoModalUrlRedirect != "") {
                  window.location.replace(`${demoModalUrlRedirect}`);
                  return;
                }
                handleCloseModal(false);
              }}
            >
              <ContenLabel>
                {`${getLangLabel("CLICK HERE TO GET FULL ACCESS")}`}
              </ContenLabel>
            </BtnLink>
          </BtnOkContainer>
        </InfoMainContainer>
      </MainContainer>
    </ModalCustom>
  );
};
