import { Col } from "react-bootstrap";
import { useBreakpoints } from "@hooks/useBreakpoints";
import {
  Container,
  Title,
  TitleRow,
  PaymentMethodImageContainer,
  ImgPaymentMethod,
} from "./styles";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { DropDownCards } from "./DropDownCards";
import {
  useGetUserCreditCards,
  usePaymentMethods,
} from "@hooks/usePaymentMethods";
import {
  isAlphanumeric,
  NEW_CARD,
  PAY_WITH_NEW_CARD,
  usePaymentMethodsStore,
} from "@store/paymentMethodsStore";
import { shallow } from "zustand/shallow";
import {
  ELEMETS_ADD_NEW_CARD,
  ELEMETS_PAY_NEW_CARD,
  INSPIRA_PG,
  STRIPE_ELEMENTS,
} from "@data/paymentData";
import { useEffect, useState } from "react";
import { getCountryCode, getCurrencyCode } from "@helpers/helper";
import { useAuth } from "@contexts/AuthContext";
import { useWeeksCheckoutStore } from "@store/weeksCheckoutStore";
import { fixedNumber } from "@lib/paymentCardByOrg/logicProgram";
import { ModalStripeElementsProccessCardFail } from "@components/Book/ModalStripeElementsProccessCardFail";
import { BrandsCreditCards } from "@components/Book/PaymentMethods/BrandsCreditCards";
import { paymentMethodsPgV2Store } from "@store/paymentMethodsPgV2Store";
import { DropDownCardsV2 } from "./DropDownCardsV2";
type CreditCardsProps = {
  ids: string | string[];
  productType: Wallet_Product_Type;
  totalAmount: number;
  extraParamsToken?: any;
  show: boolean;
  isOnlyCreditCards: boolean;
};

export const CreditCards: React.FC<CreditCardsProps> = ({
  ids,
  productType,
  totalAmount,
  extraParamsToken,
  show,
  isOnlyCreditCards,
}) => {
  const { currentUser } = useAuth();
  const { breakpoints } = useBreakpoints();
  const { getLangLabel } = useWhiteLabelLan();
  usePaymentMethods();
  useGetUserCreditCards();
  const {
    setSelectedCreditCard,
    fetchGenerateTokenPG,
    fetchGetUserCreditCards,
    getSelectedCreditCard,
    getCreditCardsOptions,
  } = usePaymentMethodsStore();
  const {
    creditCardsOptions,
    selectedCreditCard,
    suppliers,
    showModalPaymentTokenFail,
    walletCheckOutPaymentSummary,
    isEnabledPGV2,
  } = usePaymentMethodsStore(
    (state) => ({
      creditCardsOptions: state.creditCardsOptions,
      selectedCreditCard: state.selectedCreditCard,
      suppliers: state.suppliers,
      showModalPaymentTokenFail: state.showModalPaymentTokenFail,
      walletCheckOutPaymentSummary: state.walletCheckOutPaymentSummary,
      isEnabledPGV2: state.isEnabledPGV2,
    }),
    shallow
  );
  const { cashCardApplied, couponCodeApplied } = useWeeksCheckoutStore(
    (state) => ({
      // totalAmount: state.totalAmount,
      cashCardApplied: state.cashCardApplied,
      couponCodeApplied: state.couponCodeApplied,
    }),
    shallow
  );
  const {
    setCustomer,
    setPaymentInfo,
    setLoadSaveNewCardForm,
    setInitPaymentData,
  } = paymentMethodsPgV2Store();

  const { travelerCustomer } = paymentMethodsPgV2Store((state) => ({
    travelerCustomer: state.travelerCustomer,
  }));

  const enable = suppliers.some((s) =>
    [STRIPE_ELEMENTS, INSPIRA_PG].includes(s.id)
  );

  const isNewPg = suppliers.some((s) => [INSPIRA_PG].includes(s.id));

  const [showStripeElementsCardFail, setShowStripeElementsCardFail] =
    useState(false);
  const [isPaymentWithNewCardDisabled, setIsPaymentWithNewCardDisabled] =
    useState<boolean>(false);
  const [autoAddNewCard, setAutoAddNewCard] = useState<boolean>(false);
  const [autoSetCardFirstTime, setAutoSetCardFirstTime] =
    useState<boolean>(false);
  const handleSetShowStripeElementsCardFail = (state: boolean) => {
    setShowStripeElementsCardFail(state);
  };
  const updateccinfo = (optionSelected: UserCreditCardOptionsType) => {
    if (isEnabledPGV2) {
      return;
    }
    setSelectedCreditCard(optionSelected.value);
    fetchGetUserCreditCards(currentUser?.email || "");
  };

  useEffect(() => {
    // * new PgV2
    if (isEnabledPGV2 && (currentUser || travelerCustomer)) {
      console.log("🚀 ~ useEffect ~ selectedCreditCard:", selectedCreditCard);
      const defCountryCode = getCountryCode();
      setCustomer({
        email: currentUser?.email || travelerCustomer?.email || "",
        first_name:
          currentUser?.first_Name || travelerCustomer?.first_name || "",
        last_name: currentUser?.last_name || travelerCustomer?.last_name || "",
        phone: currentUser?.phoneNo || travelerCustomer?.phone || "",
        address: {
          country_code: String(defCountryCode),
        },
      });

      if (selectedCreditCard == NEW_CARD) {
        setPaymentInfo({
          amount: 1,
          amount_to_capture: 0,
          currency: getCurrencyCode(),
          email: currentUser?.email || "",
          country_code: String(defCountryCode),
        });
        setLoadSaveNewCardForm(true);
      } else {
        setLoadSaveNewCardForm(false);
        if (selectedCreditCard == PAY_WITH_NEW_CARD) {
          console.log(
            "🚀 ~ useEffect ~ selectedCreditCard:",
            selectedCreditCard
          );
          setInitPaymentData({
            amount: totalAmount,
            amount_to_capture: totalAmount,
            currency: getCurrencyCode(),
            email: currentUser?.email || travelerCustomer?.email || "",
            country_code: String(defCountryCode),
            order_id: "",
          });
        }
      }
      return;
    }
    // * end new PgV2

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCreditCard, isEnabledPGV2]);

  useEffect(() => {
    // * new PgV2
    if (isEnabledPGV2) {
      return;
    }
    // * end new PgV2

    if (selectedCreditCard && currentUser && totalAmount > 0) {
      const paymentMethodSelected = creditCardsOptions.find(
        (t) => t.value == selectedCreditCard
      );
      let cardData: {
        card_id?: string;
        payment_method: string;
        cust_id?: string;
      } = {
        payment_method: "",
      };
      if (paymentMethodSelected?.cardData) {
        const cardDataSelected = paymentMethodSelected?.cardData;
        cardData = {
          card_id: cardDataSelected.cca_card_id,
          cust_id: cardDataSelected.cca_customer_token,
          payment_method: "save_card",
        };
      } else if (
        String(selectedCreditCard).toLowerCase() ==
        ELEMETS_ADD_NEW_CARD.name.toLowerCase()
      ) {
        cardData = {
          payment_method: ELEMETS_ADD_NEW_CARD.paymentMethod,
        };
      } else {
        cardData = {
          payment_method: ELEMETS_PAY_NEW_CARD.paymentMethod,
          card_id: "",
        };
      }

      const id = typeof ids === "string" ? [ids] : ids;

      let params = {
        pg_amt: Number(totalAmount || 0),
        pg_amount: Number(totalAmount || 0),
        currency: getCurrencyCode(),
        id,
        product_type: productType,
        pg_type: "stripe",
        token: "",
        cashcard_amt: String(cashCardApplied || ""),
        cn_disc_amt: Number(
          fixedNumber(Number(couponCodeApplied?.applied_amount || 0))
        ),
        cn_disc_code: couponCodeApplied?.coupon_code || "",
        cn_dis_trnid: couponCodeApplied?.coupon_trn_id || "",
        email: currentUser?.email || "",
        first_name: currentUser?.first_Name || "",
        last_name: currentUser?.last_name || "",
        ...cardData,
        ...extraParamsToken,
      };
      console.log(
        selectedCreditCard,
        "🚀 ~ file: index.tsx:53 ~ useEffect ~ params:",
        params,
        extraParamsToken
      );
      if (productType == "WLT" && walletCheckOutPaymentSummary?.length > 0) {
        let newWallet: WalletPaymentSummaryPGTokenType[] = [];
        walletCheckOutPaymentSummary?.map((obj: WalletPaymentSummaryType) => {
          newWallet.push({
            amount: obj.amount || "",
            code: obj?.code || "",
            id: Number(obj?.id || 0),
            count: Number(obj?.count || 0),
          });
        });
        params.customer_id = cardData?.cust_id || "";
        params.wallets = newWallet;
        params.referer = window?.location?.href || "";
        params.id = "0";
        params.pg_amount = String(totalAmount || "");
        params.pg_amt = String(totalAmount || "");
        params.cashcard_amount = String(cashCardApplied || "");
        params.coupon_amount = Number(
          fixedNumber(Number(couponCodeApplied?.applied_amount || 0))
        );
        params.coupon_code = couponCodeApplied?.coupon_code || "";
        params.coupon_transaction = couponCodeApplied?.coupon_trn_id || "";
      }
      if (productType == "WLT" && (!params.customer_id || !params.wallets)) {
        return () => {};
      }
      fetchGenerateTokenPG(params);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCreditCard,
    cashCardApplied,
    couponCodeApplied,
    couponCodeApplied?.coupon_code,
    totalAmount,
  ]);

  useEffect(() => {
    if (enable) {
      if (!showModalPaymentTokenFail) {
        handleSetShowStripeElementsCardFail(false);
      } else {
        try {
          const getMaxIndex = showModalPaymentTokenFail.split("_");
          const getInfoIndex = getMaxIndex[getMaxIndex?.length - 1];
          handleSetShowStripeElementsCardFail(
            getInfoIndex == "1" ? false : isNewPg ? false : true
          );
        } catch (e: any) {
          e ? null : null;
          handleSetShowStripeElementsCardFail(false);
        }
      }
    }
  }, [enable, isNewPg, showModalPaymentTokenFail]);
  useEffect(() => {
    const findCreditCardAddedDefault = creditCardsOptions?.find(
      (x) => isAlphanumeric(x.value) && x.cardDataPGV2?.is_default
    );
    const findCreditCardAdded = creditCardsOptions?.find((x) =>
      isAlphanumeric(x.value)
    );
    const currentCrediCardToSelect: UserCreditCardOptionsType | undefined =
      findCreditCardAddedDefault || findCreditCardAdded;
    if (!autoSetCardFirstTime && currentCrediCardToSelect) {
      setAutoSetCardFirstTime(true);
      setSelectedCreditCard(
        currentCrediCardToSelect?.value || ELEMETS_ADD_NEW_CARD.name
      );
    }
  }, [creditCardsOptions, autoSetCardFirstTime, setSelectedCreditCard]);
  useEffect(() => {
    if (productType == "SUB") {
      const options =
        getCreditCardsOptions()?.length > 0
          ? getCreditCardsOptions()
          : creditCardsOptions;
      const findCreditCardAddedDefault = options?.find(
        (x) => isAlphanumeric(x.value) && x.cardDataPGV2?.is_default
      );
      const findCreditCardAdded = options?.find((x) => isAlphanumeric(x.value));
      const currentCrediCardToSelect: UserCreditCardOptionsType | undefined =
        findCreditCardAddedDefault || findCreditCardAdded;

      const isAlphanumericSelectedCard: boolean = isAlphanumeric(
        getSelectedCreditCard()
      );

      setIsPaymentWithNewCardDisabled(!currentCrediCardToSelect ? true : false);
      console.log(
        `CrediCardLogs()_ selectedCrediCard: ${getSelectedCreditCard()}, isAlphanumericSelectedCard: ${isAlphanumericSelectedCard}`,
        {
          currentCrediCardToSelect: currentCrediCardToSelect,
          autoAddNewCard: autoAddNewCard,
          creditCardsOptions: creditCardsOptions,
          getCreditCardsOptions: getCreditCardsOptions(),
        }
      );

      /* The next if is for:
        When is Selected Pay With New Card
        And then the user Use an Coupon Code and / or Cash Card and the final amount is zero sdsd
       */

      if (
        !isAlphanumericSelectedCard &&
        totalAmount == 0 &&
        ELEMETS_PAY_NEW_CARD?.name == getSelectedCreditCard()
      ) {
        setSelectedCreditCard(
          currentCrediCardToSelect?.value || ELEMETS_ADD_NEW_CARD.name
        );
      } else if (!isAlphanumericSelectedCard) {
        if (
          ELEMETS_PAY_NEW_CARD?.name == getSelectedCreditCard() &&
          !autoAddNewCard &&
          !currentCrediCardToSelect
        ) {
          setAutoAddNewCard(true);
          setSelectedCreditCard(ELEMETS_ADD_NEW_CARD.name);
        }
      }
    }
  }, [
    getSelectedCreditCard,
    getCreditCardsOptions,
    totalAmount,
    productType,
    setSelectedCreditCard,
    autoAddNewCard,
    creditCardsOptions,
  ]);

  return (
    <Container theme={{ show: show }}>
      {!isOnlyCreditCards && (
        <>
          <TitleRow>
            <ImgPaymentMethod
              className="ImgPaymentMethod"
              src={"/images/wallet/PaymentMethod1.svg"}
            />
            <Title className="titlepaymentmethodsCC">
              {getLangLabel("Payment Methods")}
            </Title>
          </TitleRow>
          <PaymentMethodImageContainer>
            <BrandsCreditCards originExecution="checkOut" />
          </PaymentMethodImageContainer>
        </>
      )}

      <Col style={{ paddingLeft: "2rem", paddingTop: "1rem" }}>
        {!isEnabledPGV2 && (
          <DropDownCards
            options={creditCardsOptions}
            value={selectedCreditCard}
            onChange={(opt) => {
              setSelectedCreditCard(opt.value);
            }}
            enable={enable}
            // enable={true}
            updateccinfo={(optionSelected) => {
              updateccinfo(optionSelected);
            }}
          />
        )}
        {isEnabledPGV2 && (
          <DropDownCardsV2
            options={creditCardsOptions}
            value={selectedCreditCard}
            onChange={(opt) => {
              setSelectedCreditCard(opt.value);
            }}
            enable={enable}
            // enable={true}
            updateccinfo={(optionSelected) => {
              updateccinfo(optionSelected);
            }}
            isPaymentWithNewCardDisabled={isPaymentWithNewCardDisabled}
          />
        )}
      </Col>

      <ModalStripeElementsProccessCardFail
        show={showStripeElementsCardFail}
        isMobile={breakpoints.down("lg")}
        handleCloseModal={handleSetShowStripeElementsCardFail}
      />
    </Container>
  );
};
