import { paymentMethodsPgV2Store } from "@store/paymentMethodsPgV2Store";
import {
  NEW_CARD,
  PAY_WITH_NEW_CARD,
  usePaymentMethodsStore,
} from "@store/paymentMethodsStore";
import { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import PGSwitchSDK from "@inspira-npm/pg-switch-js";
import { getHost } from "@helpers/helper";
import { PATH_PG_SWITH } from "@helpers/config";
import { getImageNameV2 } from "@helpers/paymentMethods";
import { useAuth } from "@contexts/AuthContext";

// const publicKey =
//   "pk_test_09bf655ff5b950ea4fd95328302bc896e83d7a0725a2adfffbf7e046f62bbd38";
// const pathPgSwith = "https://uat-routen.codestack.ae/api";
const pathPgSwith = PATH_PG_SWITH;

export const usePgSwitchSDK = (): any => {
  const { isLogged } = useAuth();
  const [pgSwitchSDK, setPgSwitchSDK] = useState<any>(null);
  const { setIsCustomerLoaded } = paymentMethodsPgV2Store();
  const {
    fetchGetUserCreditCardsPGV2,
    setCreditCardsOptions,
    setSelectedCreditCard,
  } = usePaymentMethodsStore();
  const { customer, loadCards, isCustomerLoaded } = paymentMethodsPgV2Store(
    (state) => ({
      customer: state.customer,
      loadCards: state.loadCards,
      isCustomerLoaded: state.isCustomerLoaded,
    }),
    shallow
  );

  const { apiKeySupplier, isEnabledPGV2, selectedCreditCard } =
    usePaymentMethodsStore((state) => ({
      apiKeySupplier: state.apiKeySupplier,
      isEnabledPGV2: state.isEnabledPGV2,
      selectedCreditCard: state.selectedCreditCard,
    }));

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ getHost:", getHost());

    if (apiKeySupplier && isEnabledPGV2) {
      const pgSwitchSDKInit = new PGSwitchSDK(
        pathPgSwith,
        apiKeySupplier,
        "API_SECRET_1"
      );
      setPgSwitchSDK(pgSwitchSDKInit);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKeySupplier, isEnabledPGV2]);

  async function initCustomer() {
    setIsCustomerLoaded(false);
    if (!pgSwitchSDK) {
      console.log("SDK not initialized");
      return;
    }
    if (!customer) {
      console.log("customer is missing");
      return;
    }
    await pgSwitchSDK?.createCustomer({
      ...customer,
      phone: customer.phone || "555",
    });
    setIsCustomerLoaded(true);
  }

  const fetchCards = async () => {
    if (!pgSwitchSDK) {
      console.log("SDK not initialized");
      return;
    }
    if (!customer) {
      console.log("customer is missing");
      return;
    }

    const cards = await pgSwitchSDK.cards({ limit: 100 });
    console.log("🚀 ~ fetchCards ~ cards:", cards);
    if (cards && cards.length > 0) {
      // setCards(cards);
      fetchGetUserCreditCardsPGV2(cards);
    } else {
      setCreditCardsOptions([
        {
          value: NEW_CARD,
          label: "Add new card",
          title: "Add new card",
          cardImage: getImageNameV2("add"),
          uniquekey: "add",
        },
        {
          value: PAY_WITH_NEW_CARD,
          label: "Pay with new card",
          title: "Pay with new card",
          cardImage: getImageNameV2("new_card"),
          uniquekey: "other",
        },
      ]);

      if (selectedCreditCard != NEW_CARD) {
        setTimeout(() => {
          setSelectedCreditCard(PAY_WITH_NEW_CARD);
        }, 200);
      }
    }
  };

  useEffect(() => {
    if (isCustomerLoaded && isLogged) {
      fetchCards();
    } else {
      setCreditCardsOptions([
        {
          value: PAY_WITH_NEW_CARD,
          label: "Pay with new card",
          title: "Pay with new card",
          cardImage: getImageNameV2("new_card"),
          uniquekey: "other",
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadCards, isCustomerLoaded, isLogged]);

  useEffect(() => {
    initCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, pgSwitchSDK]);

  return { pgSwitchSDK, apiKeySupplier };
};
