import { classNames } from "@helpers/helper";
import { useEffect, useRef, useState } from "react";
import { getPlansV2 } from "@lib/api/layout";
import PlansList from "./PlansList";
import HeaderPlan from "./HeaderPlan";
import { useLayoutStore } from "@store/layoutStore";
import { shallow } from "zustand/shallow";
import { useRouter } from "next/router";
import PaymentSubscription from "./PaymentSubscription";
import YourMembership from "./ModalRenewInfo/YourMembership";
import { useAuth } from "@contexts/AuthContext";
import { checkCookies } from "cookies-next";

/* eslint-disable @next/next/no-img-element */
type Props = {
  onClose: () => void;
};
export const PlansModal = ({ onClose }: Props) => {
  const scrollTargetRef = useRef<HTMLDivElement>(null);
  const { currentUser, isLogged } = useAuth();
  const router = useRouter();
  const [initBookData, setInitBookData] = useState<InitBookSubsPlanType | null>(
    null
  );
  const [data, setData] = useState<DataPlansV2Type | null>(null);
  const { setOpenPlansModal, setPlansv2Data } = useLayoutStore();
  const { openPlansModal } = useLayoutStore(
    (state) => ({
      openPlansModal: state.openPlansModal,
    }),
    shallow
  );

  const scrollToTarget = () => {
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const subsCriptionData = data?.userProfile.subscription_details;

  const isRenewalPlan =
    data && data?.userProfile.subscription_details.length > 0 ? true : false;

  useEffect(() => {
    if (isLogged) {
      getPlansV2(currentUser?.userId || "").then((res) => {
        setData(res?.data || null);
        setPlansv2Data(res?.data || null);
        let showPlansCookie = checkCookies("noShowPlansHome");
        if (
          res?.data.displayModal &&
          ["/home", "/"].includes(router.pathname) &&
          !showPlansCookie
        ) {
          setOpenPlansModal(true);
        }
      });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  useEffect(() => {
    if (data && data.userProfile.subscription_details) {
      const { sumDebt } = data;
      const { subscription_details } = data.userProfile;
      const detail = subscription_details.find((f) => f);
      const membershipStatus = detail?.plan_status || "";
      if (detail && membershipStatus !== "ACTIVE") {
        const payDeb = sumDebt ? Number(detail.subscription_debt) : 0;
        setInitBookData({
          sub_plan_id: detail.plan_id,
          currency: detail.plan_currency,
          subscription_code: detail.plan_code,
          membership_id: detail.plan_id,
          plan_amount: Number(detail.plan_price),
          userId: String(currentUser?.userId),
          referer: router.asPath,
          plan_charge_after: 0,
          charge_org: detail.next_payment_by_org,
          subscription_debt: payDeb,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, currentUser]);

  return (
    <div
      id="static-modal-plans"
      data-modal-backdrop="static"
      tabIndex={-1}
      aria-hidden="true"
      className={classNames(
        "tw-overflow-y-auto tw-bg-[var(--var-inspira--bk-purple)] tw-overflow-x-hidden tw-fixed tw-top-0 tw-right-0 tw-left-0 tw-z-50 tw-flex tw-justify-center tw-items-center tw-align-middle tw-w-full  md:tw-inset-0 tw-h-[calc(100%)] tw-max-h-full lg:tw-pt-12",
        openPlansModal ? "" : "tw-hidden"
      )}
    >
      <div className="tw-relative tw-w-full lg:tw-max-w-5xl tw-max-h-full">
        <div className=" tw-relative tw-bg-white tw-shadow tw-rounded-3xl lg:tw-mx-7 tw-mx-3">
          <div className="tw-relative tw-flex  tw-justify-end md:tw-justify-center tw-shadow-lg md:tw-shadow-none tw-w-full">
            <button
              type="button"
              onClick={onClose}
              className="tw-absolute -tw-top-2 md:-tw-right-3 tw-m-7 tw-text-2xl tw-text-gray-500 tw-shadow-2xl tw-rounded-full"
            >
              <img
                src="/images/PersonalWallet/CloseBlueModal.svg"
                alt="close"
                className="tw-w-10 tw-h-10 tw-rounded-full"
              />
            </button>
          </div>
          {openPlansModal && (
            <div className="lg:tw-px-12 tw-px-6 tw-py-12">
              <HeaderPlan isRenewal={isRenewalPlan} />
              {data?.needsRenewal && (
                <YourMembership
                  key={"subs-payment-renew"}
                  data={subsCriptionData}
                  sumDeb={data?.sumDebt || false}
                />
              )}
              {data?.needsSubscription && (
                <PlansList
                  data={data}
                  setInitBookData={setInitBookData}
                  scrollPayment={scrollToTarget}
                />
              )}
              {initBookData && (
                <PaymentSubscription
                  key={"subs-payment-methods-pay"}
                  data={data}
                  initBookData={initBookData}
                  scrollDiv={scrollTargetRef}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
