export const getImageNameV2 = (name: string, version = "v2"): string => {
  name = name.toLowerCase();
  if (version == "v2") {
    const path = "/images/payment-methods/";
    if (name == "add") {
      return path + "add.svg";
    }
    if (name == "delete") {
      return path + "delete.svg";
    }
    if (name == "edit") {
      return path + "edit.svg";
    }
    if (name == "new_card") {
      return path + "new_card.svg";
    }
    if (name.includes("amex") || name.includes("american")) {
      return path + "amex.svg";
    }
    if (name.includes("master")) {
      return path + "master.svg";
    }
    if (name.includes("visa")) {
      return path + "visa.svg";
    }
    return path + "new_card.svg";
  } else {
    const path = "/images/paymentMethods/";
    if (name == "visa") {
      return path + "visa.jpeg";
    }
    if (name.includes("master")) {
      return path + "mastercard.png";
    }
    return path + "general.png";
  }
};
export const cardBrandStandar = (name: string): string => {
  name = String(name).toLowerCase();

  if (name.includes("amex") || name.includes("american")) {
    return "amex";
  }
  if (name.includes("master")) {
    return "master";
  }
  if (name.includes("visa")) {
    return "visa";
  }
  return name;
};
