import axios from "axios";

interface DataType {
  country_code: string;
  card_id: string;
  ip: string;
  email: string;
  order_id: string;
  amount: number;
  currency: string;
  description: string;
  subscription: boolean;
  metadata?: any;
  apiKeySupplier: string;
  checksum: string;
}

export const paymentWithSavedCard = async (
  data: DataType
): Promise<PGV2SavedCardType2 | null> => {
  try {
    const response = await axios.post(`/api/pgv2/paymentWithSavedCard`, {
      ...data,
    });
    return response.data;
  } catch (error: any) {
    console.info(error);
    return error;
  }
};
