import { create } from "zustand";
interface CommonCheckoutState {
  isDisableCompleteButton: boolean;
  activeLoader: boolean;
  section: string;
  setSection: (value: string) => void;
  setIsDisableCompleteButton: (val: boolean) => void;
  setActiveloader: (val: boolean) => void;
}

export const useCommonCheckoutStore = create<CommonCheckoutState>((set) => ({
  isDisableCompleteButton: false,
  activeLoader: false,
  section: "",
  setSection: (value: string) => set({ section: value }),
  setIsDisableCompleteButton: (value: boolean) =>
    set({ isDisableCompleteButton: value }),
  setActiveloader: (value: boolean) => set({ activeLoader: value }),
}));
