import Script from "next/script";

type GoogleManagerProps = {
  googleTagId: string | null;
};
export const GoogleManager: React.FC<GoogleManagerProps> = ({
  googleTagId,
}) => {
  // Global Site Tag (gtag.js) - Google Analytics
  if (googleTagId) {
    return (
      <>
        <Script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${googleTagId}`}
        />
        <Script
          id="google-tag-manager-script-manager"
          dangerouslySetInnerHTML={{
            __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${googleTagId}', {
        page_path: window.location.pathname,
      });
      `,
          }}
        />
        {/* <!-- Google Tag Manager --> */}
        <Script
          id="google-tag-manager-script"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${googleTagId}');`,
          }}
        ></Script>
      </>
    );
  }

  return null;
};
