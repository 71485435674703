import axios from "axios";
type AdsCallsRequest = {
  pathName: string;
  orgId: number;
  checkinDate: string;
};

export const getAdsRespApi = async (
  data: AdsCallsRequest
): Promise<MainCardType[]> => {
  try {
    const response = await axios.post(`/api/productCards/getCardCalls`, {
      data,
    });

    return response.data;
  } catch (error) {
    console.info(error);
    return [];
  }
};
