import { ENABLED_PERSONAL_WALLET } from "@helpers/config";
import currencyList from "@data/CurrencyList.json";
import { getCurrencyRate } from "@lib/api/general/getCurrencyInfoApi";

export const getCurrencySimbol = (currency: string | undefined) => {
  const code = currency ? currency : "USD";
  const symb = currencyList.filter((entry) =>
    Object.values(entry).some(
      (val) => typeof val === "string" && val.match(code)
    )
  );
  return symb.length > 0 ? symb[0].symbol : "$";
};
export const initPaymentCardByOrgObject = () => {
  const initObject: Get_Payment_Card_By_Org_API_Response_Type = {
    payment_card_id: 0,
    payment_card_type: 0,
    payment_card_image: "",
    payment_card_thumbnail_image: "",
    payment_card_name: "",
    payment_card_description: "",
    payment_card_org_type: 0,
    payment_card_org_id: 0,
    payment_card_user_segment: 0,
    payment_card_currency: "",
    payment_card_validity_years: 0,
    payment_card_validity_months: 0,
    payment_card_validity_days: 0,
    payment_card_purchase_price: "",
    payment_card_redemption_price: "",
    payment_card_status: 0,
    payment_creation_time: "",
    modified_time: "",
    mst_enum_id: 0,
    mst_type_code: "",
    mst_type_name: "",
    mst_type_id: 0,
    mst_values: "",
    payment_card_service: 0,
    payment_product_name: "",
    payment_product_code: null,
    isInsert: "UNSET",
    services: [],
  };
  return initObject;
};
export const fixedNumber = (number: number) => {
  return Number(number % 1 > 0 ? number.toFixed(2) : number.toFixed(0));
};
const getRedemptionCertificateDiscount = (
  paymentCardByOrd: Get_Payment_Card_By_Org_API_Response_Type
) => {
  return fixedNumber(
    Number(paymentCardByOrd?.payment_card_redemption_price || 0)
  );
};
export const currencyConstantChanges = async (
  serviceCurrencys: string,
  currency_page: string
) => {
  let newChange: Wallet_Personal_Currency_Change_type = {
    page_currency: "",
    wallet_currency: "",
    const_change: 0,
  };
  try {
    const response = await getCurrencyRate({
      fromCurrency: serviceCurrencys,
      toCurrency: currency_page,
    });
    newChange.page_currency = currency_page;
    newChange.wallet_currency = serviceCurrencys;
    newChange.const_change = response?.roeRate || 0;
  } catch (e: any) {
    console.log(`error currencyConstantChanges()_`, { e: e });
  }
  return newChange;
};
export const getDifferentsCurrenctsByGetpaymentcardbyorg = (
  paymentcardbyorgData: Get_Payment_Card_By_Org_API_Response_Type[]
) => {
  let currencySaved: string[] = [];

  const data: Get_Payment_Card_By_Org_API_Response_Type[] =
    paymentcardbyorgData || [];
  data?.map((obj) => {
    const fingCurrencySaved = currencySaved.find(
      (f) => f == obj?.payment_card_currency
    );
    if (!fingCurrencySaved) {
      currencySaved.push(obj?.payment_card_currency || "USD");
    }
  });

  return currencySaved;
};
export const getDifferentsCurrenctsByCerfificates = (
  paymentMethods: Wallet_Personal_API_PaymentMethods_Type | null,
  type: Wallet_Personal_Current_Selected_Type
) => {
  let currencySaved: string[] = [];
  if (type == "certificates") {
    const data: Wallet_Personal_API_Certificate_Data_Type[] =
      paymentMethods?.certidata || [];
    data?.map((obj) => {
      const fingCurrencySaved = currencySaved.find(
        (f) => f == obj?.currency_code
      );
      if (!fingCurrencySaved) {
        currencySaved.push(obj?.currency_code || "USD");
      }
    });
  }
  if (type == "discount_cards") {
    const data: Wallet_Personal_API_DiscountCard_Data_Type[] =
      paymentMethods?.discountcarddata || [];
    data?.map((obj) => {
      const fingCurrencySaved = currencySaved.find(
        (f) => f == obj?.currency_code
      );
      if (!fingCurrencySaved) {
        currencySaved.push(obj?.currency_code || "USD");
      }
    });
  }
  return currencySaved;
};
/* GET CERTIFICATE PREVIEW */
export const getBestCertificateToCompare = (
  paymentCardByOrd: Get_Payment_Card_By_Org_API_Response_Type[],
  product_type: Wallet_Product_Type,
  currency: string
) => {
  currency ? null : null;
  let returnInformation: Get_Payment_Card_By_Org_API_Response_Type =
    initPaymentCardByOrgObject();
  ENABLED_PERSONAL_WALLET &&
    Array.isArray(paymentCardByOrd) &&
    paymentCardByOrd?.map((obj: Get_Payment_Card_By_Org_API_Response_Type) => {
      const payment_card_redemption_price: boolean =
        Number(obj?.payment_card_redemption_price || 0) > 0;
      if (
        product_type == obj?.payment_product_code &&
        payment_card_redemption_price
      ) {
        if (returnInformation?.isInsert == "UNSET") {
          returnInformation = obj;
          obj.isInsert = "SET";
        } else {
          const currentRedepmtion: number =
            getRedemptionCertificateDiscount(returnInformation);
          const toChangeRedemption: number =
            getRedemptionCertificateDiscount(obj);
          if (
            currentRedepmtion < toChangeRedemption &&
            payment_card_redemption_price
          ) {
            returnInformation = obj;
            obj.isInsert = "SET";
          }
        }
      }
    });
  return returnInformation;
};

export const getTotalPriceForBestCertificate = (
  inclusivePrice: number,
  paymentCardByOrd: Get_Payment_Card_By_Org_API_Response_Type
) => {
  const currentExclusivePrice: number = fixedNumber(inclusivePrice);
  const discount: number = getRedemptionCertificateDiscount(paymentCardByOrd);
  const FinalPricePreview: number = currentExclusivePrice - discount;
  let finalExclusivePrice: number = Math.max(fixedNumber(FinalPricePreview), 0);
  return finalExclusivePrice;
};
