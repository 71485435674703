import { Col, Container, Modal, Row } from "react-bootstrap";
import styled from "styled-components";

export const MainContainer = styled(Container)`
  display: ${({ theme }: any) => (theme.show ? "block" : "none")};
  background-color: #fff;
  text-align: center;
  border-radius: 15px;
  margin-top: 2rem;
  box-shadow: 0 0 10px 0 #ccc;
`;

export const Title = styled(Col)`
  color: ${({ theme }) => theme.colors.checkout.purple[2]};
  color: #000;
  text-align: left;
  font-weight: 600;
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border-bottom: 1px solid #ccc;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  padding: 1rem 4rem 0.5rem 4rem;
`;

export const SubTitleRow = styled(Row)`
  padding-top: 1rem;
`;

export const SubTitle = styled(Title)`
  padding-top: 0rem;
  padding-left: 2rem;
  background-image: url("/images/hotel-checkout/creditcard.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px 20px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.checkout.purple[0]};
`;

export const RowInputs = styled(Row)`
  padding: 2rem 0rem;
  @media only screen and (max-width: 992px) {
    padding: 0rem 0rem;
  }
`;

export const ModalContainer = styled(Modal)`
  background: rgba(64, 67, 120, 0.75);
  & .modal-header {
    border-bottom: 0;
  }
  & .modal-content {
    border-radius: 20px;
    background-color: #ecf1f5;
  }
  & .modal-dialog {
    max-width: 40rem;
  }
`;

export const MainPaymentContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 2rem;
  @media only screen and (max-width: 992px) {
    display: block;
  }
`;

export const PaymentContainer = styled.div`
  display: ${({ theme }: any) => (theme.show ? "block" : "none")};
  width: 50%;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;
