import axios from "axios";

export const planInitbook = async (data: InitBookSubsPlanElementsType) => {
  try {
    const res = await axios.post(`/api/plans/initbook`, { data });
    return res.data;
  } catch (error) {
    return [];
  }
};
