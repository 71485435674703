import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

export const MainContainer = styled(Container)`
  background-color: #fff;
  text-align: left;
  border-radius: 15px;
  margin-top: 2rem;
  color: black;
  box-shadow: 0 0 10px 0 #ccc;
  z-index: 1;
  display: ${({ theme }) => (theme.show ? "" : "none")};
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  @media only screen and (max-width: 992px) {
    /* margin-bottom: 12rem; */
  }
`;

export const ContainerButton = styled(MainContainer)`
  border-radius: 0;
  box-shadow: 0 -5px 10px 0 #ccc;
  padding-bottom: 0;
  margin-bottom: 0;
  @media only screen and (max-width: 992px) {
    position: fixed;
    bottom: 0;
    background: #fff;
    left: 0;
    width: 100%;
    z-index: 999;
  }
`;

export const Title = styled.h1`
  color: #45486d;
  text-align: left;
  font-weight: 600;
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border-bottom: 1px solid #ccc;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  padding: 1rem 4rem 0.5rem 4rem;
`;

export const RowDiv = styled(Row)`
  padding-top: 0rem; //1rem;
  padding-bottom: 0rem; //1rem;
`;

export const RowDivButton = styled(RowDiv)`
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

export const ColDiv = styled(Col)`
  text-align: justify;
  font-size: 13px;
  color: #727272;
  font-weight: 600;

  padding-left: 1rem;
  padding-right: 1rem;
  @media only screen and (min-width: 992px) {
    padding-left: 3rem; //4rem;
    padding-right: 3rem; //4rem;
  }
`;

export const Span = styled.span`
  color: rgb(104, 109, 164);
  color: #686ea3;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
`;
export const TermsOfBooking = styled.span`
  display: ${({ theme }: any) => (theme.show ? "" : "none")};
`;
export const TermsAndConditionsLink = styled.a`
  text-decoration: underline;
  font-size: 13px;
  font-weight: 600;
  color: #686ea3;
  cursor: pointer;
  margin-left: 0.4rem;
  margin-right: ${({ theme }: any) =>
    theme.notApplyRightSpace ? "0rem" : "0.4rem"};
  margin-top: -0.1rem;
`;
export const TermsAndConditionsNoLink = styled.span`
  text-decoration: underline;
  font-size: 13px;
  font-weight: 600;
  color: #686ea3;
  cursor: pointer;
  //margin-left: 0.4rem;
  //margin-right: 0.4rem;
  margin-top: -0.1rem;
`;
export const TermsBookingSpan = styled.span`
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #888888;
  margin-bottom: 1.2rem;
`;
