import axios from "axios";

export const cashcardRedemption = async (
  data: CarCashCardRedemptionRequestType
): Promise<CarCashCardRedemptionResponseType | null> => {
  try {
    const response = await axios.post(`/api/cars/cashcardRedemption`, {
      data,
    });
    return response?.data;
  } catch (error) {
    console.info(error);
    return null;
  }
};
