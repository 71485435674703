import { Col } from "react-bootstrap";
import { preventExecuteCodeHTMLJS } from "@helpers/helper";
import {
  BtnCustom,
  Title,
  TitleRow,
  Input,
  ColBtn,
  RowBtn,
  SubTitleRow,
  SubTitle,
  ColPaymentMethod,
  ColMethodLabels,
  ImgPaymentMethod,
  DivContentText,
} from "./styles";

type InputCustomProps = {
  title: string;
  imageName: string;
  value: string | number;
  onChange: (val: string) => void;
  onClick: () => void;
  textBtn: string;
  placeholder: string;
  subtitle?: string;
  currency?: string;
  inputType?: "text" | "number";
  message?: string;
  disabled?: boolean;
  successMessage?: boolean;
};

export const InputCustom: React.FC<InputCustomProps> = ({
  title,
  imageName,
  value,
  onChange,
  textBtn,
  placeholder,
  subtitle,
  successMessage,
  inputType = "text",
  message,
  onClick,
  disabled,
}) => {
  return (
    <ColPaymentMethod>
      <ColMethodLabels theme={{ imageName }}>
        <DivContentText>
          <ImgPaymentMethod
            theme={{ imageName }}
            src={"/images/wallet/" + imageName + ".svg"}
          />
          <TitleRow>
            <Title>{title}</Title>
          </TitleRow>
        </DivContentText>
        {subtitle && (
          <SubTitleRow>
            <SubTitle theme={{ imageName }}>{subtitle}</SubTitle>
          </SubTitleRow>
        )}
      </ColMethodLabels>
      <Col>
        <RowBtn className="payment-mt-rbtn">
          <Col xs={9} className="p-0">
            <Input
              className="form-control"
              placeholder={placeholder}
              value={`${value}`}
              onChange={(e) =>
                onChange(preventExecuteCodeHTMLJS(String(e.target.value || "")))
              }
              type={inputType}
              min={0}
              disabled={disabled}
            />
          </Col>
          <ColBtn xs={3} className="p-0">
            <BtnCustom
              className="payment-methods-save"
              onClick={() => onClick()}
            >
              {textBtn}
            </BtnCustom>
          </ColBtn>
          {message && (
            <span
              className={`${
                successMessage ? "tw-text-green-500" : "tw-text-red-500"
              } tw-text-left tw-text-[12px] tw-font-medium tw-absolute tw--bottom-6 tw-flex`}
            >
              {message}
            </span>
          )}
        </RowBtn>
      </Col>
    </ColPaymentMethod>
  );
};
