import { loadStripe } from "@stripe/stripe-js";
import { ButtonClose, H1, ModalContainer } from "./styles";
import { useState } from "react";
import { usePaymentMethodsStore } from "@store/paymentMethodsStore";
import { shallow } from "zustand/shallow";
import { STRIPE_ELEMENTS } from "@data/paymentData";
import { useWhiteLabelLan } from "@hooks/useLayout";
type Modal3DSecureProps = {
  show: boolean;
  url3DSecure?: string;
  onCompleteBooking: (e: any, complete?: boolean) => void;
};
const STATUS_OK = "succeeded";
const STATUS_REQ_CONF = "requires_confirmation";
const STATUS_REQ_PAYMENT = "requires_payment_method";
export const Modal3DSecure: React.FC<Modal3DSecureProps> = ({
  show,
  url3DSecure,
  onCompleteBooking,
}) => {
  const { getLangLabel } = useWhiteLabelLan();
  const [message, setMessage] = useState("");
  const { setShow3dSecure } = usePaymentMethodsStore();
  const { suppliers, generatedToken } = usePaymentMethodsStore(
    (state) => ({
      suppliers: state.suppliers,
      generatedToken: state.generatedToken,
    }),
    shallow
  );
  const [loading, setLoading] = useState<boolean>(false);
  // useEffect(() => {
  const on3DSComplete = async () => {
    // Oculta la UI de autenticación 3DS (en este caso, asumimos que tienes una referencia a 'yourContainer')
    const stripeSupplier = suppliers?.find((s) => s.id == STRIPE_ELEMENTS);
    const stripePK = stripeSupplier?.userid || stripeSupplier?.["userid "];
    // yourContainer.remove();
    if (!stripePK || !generatedToken?.token) {
      return false;
    }
    const stripePromise = loadStripe(stripePK);

    // Recupera el cliente secret del PaymentIntent, reemplaza '{{PAYMENT_INTENT_CLIENT_SECRET}}' con el valor real
    const paymentIntentClientSecret = generatedToken?.token;

    const stripe = await stripePromise;

    // Recupera el PaymentIntent
    const result = await stripe?.retrievePaymentIntent(
      paymentIntentClientSecret
    );

    if (result?.error) {
      // El PaymentIntent client secret era inválido
      console.error("PaymentIntent client secret inválido:", result.error);
      setMessage("Please enter another payment method");
      return false;
    } else {
      if (result?.paymentIntent.status === STATUS_OK) {
        // Muestra al cliente que el pago ha tenido éxito
        setMessage(STATUS_OK);
        return true;
      } else if (result?.paymentIntent.status === STATUS_REQ_CONF) {
        setMessage(STATUS_OK);
        return true;
      } else if (result?.paymentIntent.status === STATUS_REQ_PAYMENT) {
        // La autenticación falló, solicita al cliente que ingrese otro método de pago
        setMessage("Please enter another payment method");
        return false;
      }
    }
    return false;
  };
  const handleSetLoading = (state: boolean) => {
    setLoading(state);
  };

  if (!url3DSecure) {
    return null;
  }

  return (
    <ModalContainer
      show={show}
      onHide={() => {}}
      size="lg"
      className="modalcolors"
    >
      <div className="vpay-payment mt-4">
        <iframe src={url3DSecure} width={600} height={500}></iframe>
      </div>
      <H1 theme={{ isOk: message == STATUS_OK }}>{message}</H1>
      <ButtonClose
        onClick={async () => {
          handleSetLoading(true);
          if (!loading) {
            const res = await on3DSComplete();
            if (res) {
              setTimeout(async () => {
                await onCompleteBooking(null, true);
                setShow3dSecure(false);
                handleSetLoading(false);
              }, 1000);
              return;
            }
            setShow3dSecure(false);
            handleSetLoading(false);
          }
        }}
      >
        {!loading ? `${getLangLabel("CLOSE")}` : <></>}
        {loading ? (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              padding: "1.4rem",
              marginTop: "-0.2rem",
            }}
          >
            <span className={"vt-loaderUserDetailsCancellation"}></span>
          </span>
        ) : (
          <></>
        )}
      </ButtonClose>
    </ModalContainer>
  );
};
