/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { useWhiteLabelLan } from "@hooks/useLayout";

type Props = {
  isRenewal?: boolean;
};
const HeaderPlan = ({ isRenewal = false }: Props) => {
  const { getLangLabel } = useWhiteLabelLan();
  return (
    <>
      <div className=" tw-flex tw-w-full tw-justify-center tw-align-middle tw-items-center tw-flex-col tw-gap-4">
        <h1 className=" tw-text-black tw-flex tw-justify-start tw-align-middle tw-items-center tw-text-3xl tw-font-bold tw-w-full">
          <img
            src="/images/membership-upgrade/signup-main-ico-black.svg"
            className="tw-w-10 tw-h-10 tw-mr-2"
          />
          {isRenewal
            ? getLangLabel("Account Renewal")
            : getLangLabel("Choose your plan")}
        </h1>
        <h1 className=" tw-text-orange-500 tw-text-3xl tw-font-semibold tw-w-full">
          {isRenewal
            ? getLangLabel(
                "Please renew your account to continue this booking and enjoy all of our travel savings!"
              )
            : getLangLabel(
                "Please sign up to continue this booking and enjoy all of our travel savings!"
              )}
        </h1>
      </div>
    </>
  );
};

export default HeaderPlan;
