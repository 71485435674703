import { COOKIE_BOOKING_OTHER_ORG, COOKIE_ORG_ID } from "@data/menuItems";
import { checkCookies, getCookie } from "cookies-next";

export const getOrgsIds = (req: any, res: any) => {
  const originalOrgId = getCookie(COOKIE_ORG_ID, { req, res });
  const service = getCookie(COOKIE_BOOKING_OTHER_ORG, { req, res });

  if (
    checkCookies(COOKIE_BOOKING_OTHER_ORG, { req, res }) &&
    service &&
    Number(originalOrgId) > 0
  ) {
    return {
      org_id: String(originalOrgId),
      book_service: String(service),
    };
  }

  return {
    org_id: String(originalOrgId),
    book_service: "default",
  };
};

// this function only works in the client side
export const getOrgId = () => {
  const originalOrgId = getCookie(COOKIE_ORG_ID);
  const service = getCookie(COOKIE_BOOKING_OTHER_ORG);
  const path = window.location.pathname;

  if (
    checkCookies(COOKIE_BOOKING_OTHER_ORG) &&
    service &&
    Number(originalOrgId) > 0 &&
    path == "/hotel-checkout" // this is the only page that uses this function (hotel-checkout)
  ) {
    return {
      org_id: String(originalOrgId),
      book_service: String(service),
    };
  }

  return {
    org_id: String(originalOrgId),
    book_service: "default",
  };
};
