import { getCouponCodeDetails } from "@lib/api/getcouponcodedetails";
import { create } from "zustand";

interface WeeksCheckoutState {
  tsTravelers: number;
  userId?: number;
  travelerSelected?: number;
  couponCodes: CarCoupponCodeType[];
  cashCards: any[];
  isLoadingCoupons: boolean;
  isLoadingCashcard: boolean;
  couponCodeApplied: WeeksCoupponCodeType | null;
  totalAmount: number;
  cashCardApplied: number;
  uniqueId: number;
  setCashCardApplied: (val: number) => void;
  setTotalAmount: (val: number) => void;
  setCouponCodeApplied: (val: WeeksCoupponCodeType | null) => void;
  setIsLoadingCoupons: (val: boolean) => void;
  setIsLoadingCashcard: (val: boolean) => void;
  setTsTravelers: () => void;
  setUserId: (val: number) => void;
  setTravelerSelected: (val: number) => void;
  setcashCards: (val: any[]) => void;
  setCouponCodes: (val: CarCoupponCodeType[]) => void;
  fetchResortCouponCodes: (value: string) => void;
  setUniqueId: (value: number) => void;
  clearWeeksCheckStore: () => void;
}

const initData = {
  tsTravelers: Date.now(),
  userId: undefined,
  travelerSelected: undefined,
  traveler: null,
  couponCodes: [],
  isLoadingCoupons: false,
  couponCodeApplied: null,
  isLoadingCashcard: false,
  cashCards: [],
  totalAmount: -1,
  cashCardApplied: 0,
  uniqueId: -1,
};

export const useWeeksCheckoutStore = create<WeeksCheckoutState>((set) => ({
  ...initData,
  clearWeeksCheckStore: () => set({ ...initData }),
  setCashCardApplied: (val: number) => {
    set({ cashCardApplied: val });
  },
  setTotalAmount: (val: number) => {
    set({ totalAmount: val });
  },
  setcashCards: (val: any[]) => {
    set({ cashCards: val });
  },
  setIsLoadingCashcard: (val: boolean) => {
    set({ isLoadingCashcard: val });
  },
  setCouponCodeApplied: (val: WeeksCoupponCodeType | null) => {
    set({ couponCodeApplied: val });
  },
  setIsLoadingCoupons: (val: boolean) => {
    set({ isLoadingCoupons: val });
  },
  setCouponCodes: (val: CarCoupponCodeType[]) => {
    set({ couponCodes: val });
  },
  setTsTravelers: () => {
    set({ tsTravelers: Date.now() });
  },
  setUserId: (val: number) => {
    set({ userId: val });
  },
  setTravelerSelected: (val: number) => {
    set({ travelerSelected: val });
  },
  fetchResortCouponCodes: async (dataReq: any) => {
    set({ isLoadingCoupons: true });

    const data = await getCouponCodeDetails(dataReq);
    console.log("activateCouponCodes", data);
    if (data) {
      set({ couponCodes: data });
    }
    set({ isLoadingCoupons: false });
  },
  setUniqueId: (val: number) => {
    set({ uniqueId: val });
  },
}));
