import { ScrollbarStyle } from "@styles/general";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

export const DropdownMain = styled(Dropdown)`
  /* background-color: #ededf1; */
  /* margin-top: 1rem; */
  /* padding: 0 2rem; */
  & > .dropdown-toggle::after {
    margin-left: 0.5rem;
  }

  & > button {
    background-image: ${({ theme }) => `url(${theme.icon})`};
    background-repeat: no-repeat;
    background-position: left 1rem center;
    background-size: 22px;

    background-color: #ededf1;
    width: 100%;
    padding-left: 3rem;
    font-size: medium;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    /* color: #686ea3 !important; */
    color: #424577;
    border: 2px solid #aeb1d6;
    border-radius: 50px;
    box-shadow: 0 0 4px 0 #424577;
    padding: 0.8rem 4rem !important;
  }
  & > div {
    width: 100%;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    border-radius: 25px; //10px;
  }

  & > div > .active {
    color: #fff;
    text-decoration: none;
    background-color: rgba(104, 110, 163, 0.4);
  }
  .hover {
    background-color: #f0f0f4;
  }
  .dropdown-item.active {
    background-color: #f0f0f4;
  }
`;

export const DropdownToggle = styled(DropdownMain.Toggle)`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

export const DropdownMainItem = styled(DropdownMain.Item)`
  background-image: ${({ theme }) => `url(${theme.icon})`};
  background-repeat: no-repeat;
  background-position: ${({ theme }) =>
    theme.cssdefault ? "left 1.2rem center" : "left 1rem center"};
  background-size: ${({ theme }) => (theme.cssdefault ? "22px" : "25px")};
  width: 95%;
  margin: auto;
  margin-top: 1rem; //0.1rem;
  border-radius: 10px;
  padding: 0.5rem;
  padding-left: 3.5rem;
  font-size: medium;
  font-weight: 600;
  color: var(--inspira-purple);
  border-radius: 1rem;
  :hover {
    border-radius: 1rem;
  }

  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

export const DropdownMainMenu = styled(DropdownMain.Menu)`
  /* min-width: 7.5rem;
  max-height: 14rem;
  overflow: hidden;
  overflow-y: scroll;
  ${ScrollbarStyle}
  scrollbar-width: 10px;
  ::-webkit-scrollbar {
    width: 10px;
  }*/
  min-width: 7.5rem;
  //min-height: 20rem;
  max-height: 16rem;
  border-radius: 25px;
  overflow: hidden;
`;

export const CardImg = styled.img`
  width: 40px;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
`;
export const CardImg2 = styled.img`
  width: 32px;
  height: auto;
  margin-left: 0px;
  margin-right: 10px;
  margin-top: ${({ theme }) =>
    theme.cssval ? "-0.4rem !important" : "1rem !important"};
`;
export const DivWrap = styled.div`
  display: grid;
  grid-template-columns: 2.5rem; //15%;
  color: var(--bs-gray-600);
  //grid-auto-rows: 50px; /* control the height here */
`;
export const DivImg = styled.div`
  grid-row: span 3;
`;
export const DivLabel = styled.div`
  grid-row: span 1;
  grid-column: 2;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 14px; //1.1rem;
  font-weight: 700;
  text-transform: ${({ theme }) => (theme.cssval ? "capitalize" : "uppercase")};
`;
export const DivCard = styled.div`
  grid-row: span 1;
  grid-column: 2;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 14px; //1.1rem;
  font-weight: 700;
`;
export const DivExpire = styled.div`
  grid-row: span 1;
  grid-column: 2;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 12px; //1.1rem;
  font-weight: 500;
`;
export const DivDefault = styled.div`
  grid-column: 3;
  grid-row: span 3;
  margin-left: 1rem; //3rem;
  margin-top: -1.3rem;
  display: ${({ theme }) => (theme.cssval ? "none" : "")};
  //display: none;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fca667;
  text-decoration: underline;
  text-transform: capitalize;
`;
export const OuterDiv = styled.div`
  min-width: 7rem;
  max-height: 20rem;
  border: 1px solid red;
  border-radius: 15pt;
  overflow: hidden;
`;
export const InnerDiv = styled.div`
  /*min-width: 8rem;
  max-height: 16.5rem;
  overflow-y: auto;*/
  padding: 4rem 2rem;
  min-width: 6.5rem;
  max-height: 15rem;
  overflow: hidden;
  overflow-y: scroll;
  ${ScrollbarStyle}
  scrollbar-width: 10px;
  ::-webkit-scrollbar {
    width: 10px;
  }
  padding: 1rem;
`;
