/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { ADD_CARD, DYMMY_CARD, EMPTY_SELECT } from "@data/paymentData";
import { classNames } from "@helpers/helper";
import { useWhiteLabelLan } from "@hooks/useLayout";
import Image from "next/image";
import { useState } from "react";
import { EditCreditCard } from "./EditCreditCard";
import { DeleteCreditCard } from "./CreditCardDelete";
import { CreditCardDefault } from "./CreditCardDefault";
import { cardBrandStandar } from "@helpers/paymentMethods";
type DropDownCardsProps = {
  enable: boolean;
  value: string;
  options?: UserCreditCardOptionsType[];
  onChange: (optionSelected: UserCreditCardOptionsType) => void;
  updateccinfo: (optionSelected: UserCreditCardOptionsType) => void;
  isPaymentWithNewCardDisabled: boolean;
};
export const DropDownCardsV2 = ({
  options,
  value,
  onChange,
  isPaymentWithNewCardDisabled,
}: DropDownCardsProps) => {
  const { getLangLabel } = useWhiteLabelLan();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDefault, setOpenDefault] = useState(false);
  const [cardId, setCardId] = useState("");
  const [nickName, setNickName] = useState("");

  const getOptionSelected = () => {
    return options?.find((f) => f.value == value);
  };
  const cardSelected = getOptionSelected();

  const handleMenuClose = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setOpenEdit(false);
  };
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };
  const handleDefaultClose = () => {
    setOpenDefault(false);
  };

  const handleOpen = (id: string, name: string) => {
    if (id) {
      setCardId(id);
      setNickName(name);
      handleMenuClose();
      setOpenEdit(true);
    }
  };
  const handleOpenDelete = (id: string) => {
    if (id) {
      setCardId(id);
      handleMenuClose();
      setOpenDelete(true);
    }
  };
  const handleOpenDefault = (id: string) => {
    if (id) {
      setCardId(id);
      handleMenuClose();
      setOpenDefault(true);
    }
  };

  const CreditCardInfo = ({ data }: { data?: PgCardsSavedType }) => {
    if (!data) return null;
    const last4 = data.last4.slice(-4);
    return (
      <span className=" tw-flex tw-flex-col tw-text-left tw-font-semibold tw-text-sm tw-capitalize">
        <span>{data?.nick_name || data?.name}</span>
        <span>
          {cardBrandStandar(data?.brand)} ****
          {last4}
        </span>
        <span className=" tw-text-xs tw-font-normal">
          {getLangLabel("expiration")}:{data?.exp_month}
          {data?.exp_year}
        </span>
      </span>
    );
  };
  return (
    <>
      <div className="tw-relative tw-w-full tw-flex tw-flex-col">
        <button
          id="dropdownDefaultButton"
          data-dropdown-toggle="dropdown"
          className="tw-justify-between tw-border tw-border-gray-300 tw-bg-[#ededf1] hover:tw-bg-[#f7f7f9] focus:tw-ring-1  hover:tw-ring-[#424577]  focus:tw-ring-[#424577] tw-shadow-sm tw-font-medium tw-rounded-full tw-text-sm tw-px-5 tw-py-2.5 tw-text-center tw-inline-flex tw-items-center "
          type="button"
          onClick={() => setOpen(!open)}
        >
          <Image
            src={"/images/hotel-detail/checkDefault.png"}
            width={20}
            height={20}
            alt="card"
          />
          <Image
            src={cardSelected?.cardImage || DYMMY_CARD}
            width={52}
            height={34}
            alt="card"
          />
          <span className="tw-text-base tw-font-semibold">
            {!cardSelected?.cardDataPGV2 && (
              <span>
                {getLangLabel(cardSelected?.label ? cardSelected?.label : "")}
              </span>
            )}
            {cardSelected?.cardDataPGV2 && (
              <CreditCardInfo data={cardSelected?.cardDataPGV2} />
            )}
          </span>
          <svg
            id="dropdownDefaultButtonArrow"
            key={"dropdownDefaultButtonArrow"}
            className="tw-w-2.5 tw-h-2.5 tw-ms-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <div className=" tw-relative tw-w-full">
          <div
            id="dropdown"
            className={classNames(
              "tw-min-w-[597px] tw-px-2",
              " tw-absolute  tw-top-4  tw-w-auto",
              "tw-z-10 tw-bg-white tw-divide-y tw-divide-gray-100 tw-rounded-lg tw-shadow-4xl  tw-dark:bg-gray-700",
              " tw-overflow-y-auto tw-overflow-x-hidden tw-transition-all tw-duration-300 tw-ease-in-out",
              open ? "" : "tw-hidden"
            )}
          >
            <ul
              className={classNames(
                " tw-p-0 tw-my-2 tw-text-sm tw-text-gray-700 tw-dark:text-gray-200 tw-w-full",
                " tw-max-h-[320px] tw-rounded-lg"
              )}
              aria-labelledby="dropdownDefaultButton"
            >
              {options?.map((opt, index) => {
                // const data = opt.cardDataPGV2;
                const data = opt?.cardDataPGV2;
                const cardKey = opt?.cardDataPGV2?.id;
                const name =
                  opt?.cardDataPGV2?.nick_name || opt?.cardDataPGV2?.name;
                const dataV2 = opt.cardDataPGV2;
                const cardNumber = dataV2?.last4;
                // const isDefault = data?.is_default;

                let cssimgdefault = ADD_CARD;

                if (dataV2?.is_default) {
                  cssimgdefault = "/images/hotel-detail/checkDefault.png";
                }
                if (
                  isPaymentWithNewCardDisabled &&
                  opt?.value == "pay with new card"
                ) {
                  return <></>;
                }

                return (
                  <li
                    className={classNames(
                      "tw-p-0 tw-px-4 tw-w-full tw-gap-3 tw-flex tw-flex-row",
                      "hover:tw-text-[#424577] tw-text-sm tw-text-gray-700 hover:tw-bg-[#f0f0f4]",
                      "tw-rounded-lg mt-2 tw-py-2 tw-cursor-pointer",
                      "tw-justify-start tw-items-center",
                      value == opt.value ? "tw-bg-[#f0f0f4]" : ""
                    )}
                    key={`card-item-${index}`}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        onChange(opt);
                        setOpen(false);
                      }}
                      className={classNames(
                        " tw-w-auto tw-min-h-[40px]",
                        "tw-flex tw-flex-row tw-justify-start tw-align-middle tw-items-center tw-gap-3"
                      )}
                    >
                      <span
                        className={classNames(
                          " tw-text-sm tw-text-[11px] tw-text-right tw-font-semibold",
                          dataV2?.is_default ? "tw-visible" : "tw-invisible"
                        )}
                      >
                        *{getLangLabel("default")}
                      </span>
                      <img
                        src={value == opt.value ? cssimgdefault : EMPTY_SELECT}
                        width={24}
                        height={24}
                        alt="card"
                        className=" tw-w-6 tw-h-6 tw-bg-transparent tw-ml-2 "
                      />
                      <img
                        src={opt?.cardImage || DYMMY_CARD}
                        alt="card"
                        className=" tw-w-[53px] tw-h-[34px] "
                      />
                      <span
                        className={classNames(
                          "tw-min-w-[180px] tw-text-left tw-flex tw-flex-col tw-font-semibold"
                        )}
                      >
                        {!cardNumber && (
                          <span className=" tw-w-full">
                            {getLangLabel(opt.label)}
                          </span>
                        )}
                        {cardNumber && <CreditCardInfo data={data} />}
                      </span>
                    </button>
                    <div
                      className={classNames(
                        " tw-w-full flex tw-flex-row",
                        cardNumber ? "" : "tw-hidden"
                      )}
                    >
                      <button
                        type="button"
                        onClick={() => handleOpenDefault(cardKey || "")}
                        className={classNames(
                          " tw-w-auto tw-text-orange-500   tw-inline-flex tw-items-center tw-text-center",
                          "tw-text-xs tw-text-[11px] tw-justify-center",
                          "tw-rounded-full tw-h-7 tw-px-3",
                          "hover:tw-bg-[#b8b9d6] tw-rounded-full tw-h-7 tw-px-3",
                          dataV2?.is_default ? "tw-hidden" : "",
                          cardNumber ? "" : "tw-hidden"
                        )}
                      >
                        {getLangLabel("set as default")}

                        <span className="tw-sr-only">default</span>
                      </button>
                      <button
                        type="button"
                        className={classNames(
                          "tw-w-auto tw-inline-flex tw-items-center tw-justify-center tw-text-center",
                          "tw-text-xs tw-text-[11px] tw-text-[#424577]",
                          "tw-rounded-full tw-h-7 tw-px-3",
                          "hover:tw-bg-[#b8b9d6] tw-rounded-full tw-h-7 tw-px-3",
                          cardNumber ? "" : "tw-hidden"
                        )}
                        onClick={() => handleOpen(cardKey || "", name || "")}
                      >
                        {getLangLabel("Edit")}
                        <img
                          src="/images/LoyaltyProgram/Edit.svg"
                          className="tw-ml-1 tw-w-3 tw-h-3"
                        />
                        <span className="tw-sr-only">Edit</span>
                      </button>
                      <button
                        type="button"
                        onClick={() => handleOpenDelete(cardKey || "")}
                        className={classNames(
                          "tw-w-auto tw-inline-flex tw-items-center tw-justify-center tw-text-center",
                          "tw-text-xs tw-text-[11px] tw-text-[#424577]",
                          "tw-rounded-full tw-h-7 tw-px-3",
                          "hover:tw-bg-[#b8b9d6] tw-rounded-full tw-h-7 tw-px-3",
                          cardNumber ? "" : "tw-hidden"
                        )}
                      >
                        {getLangLabel("Delete")}
                        <img
                          src="/images/Profile/delete.svg"
                          className="tw-ml-1 tw-w-3 tw-h-3"
                        />
                        <span className="tw-sr-only">Delete</span>
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <EditCreditCard
        open={openEdit}
        onClose={handleClose}
        cardId={cardId}
        nickName={nickName}
      />
      <DeleteCreditCard
        open={openDelete}
        onClose={handleDeleteClose}
        cardId={cardId}
      />
      <CreditCardDefault
        open={openDefault}
        onClose={handleDefaultClose}
        cardId={cardId}
      />
    </>
  );
};
