import axios from "axios";

export const getCss = async (fileName: string): Promise<string> => {
  try {
    const response = await axios.get(
      `/api/general/getCss/${encodeURIComponent(fileName)}`
    );
    return response.data;
  } catch (error) {
    return "";
  }
};
