import axios from "axios";
type DataType = {
  orderId: string;
  currency: string;
  countryCode: string;
  amount: number;
  api_key: string;
  // publicKey: string;
};
export const generateCheckSum = async (
  data: DataType
): Promise<string | null> => {
  try {
    const response = await axios.post(`/api/pgv2/generateCheckSum`, {
      ...data,
    });
    return response?.data?.checksum;
  } catch (error) {
    console.info(error);
    return null;
  }
};
