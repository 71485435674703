export const pagesName = [
  {
    path: "hotelgd",
    label: "Green Deals",
    code: "HLTGD",
    images: {
      on: "/images/general/pages/HotelOn.svg",
      off: "/images/general/pages/HotelOff.svg",
    },
    order: 100,
  },
  {
    path: "hotel",
    label: "hotel",
    code: "HLT",
    images: {
      on: "/images/general/pages/HotelOn.svg",
      off: "/images/general/pages/HotelOff.svg",
    },
    order: 101,
  },
  {
    path: "condo",
    label: "Condos",
    code: "SBM",
    images: {
      on: "/images/general/pages/HotelOn.svg",
      off: "/images/general/pages/HotelOff.svg",
    },
    order: 102,
  },
  {
    path: "stays",
    label: "Complimentary Stays",
    code: "STY",
    images: {
      on: "/images/general/pages/StaysOn.png",
      off: "/images/general/pages/StaysOff.svg",
    },
    order: 103,
  },
  {
    path: "https://destinations.promos.network/resort_main",
    label: "All-Inclusive",
    code: "allInclusive",
    isNewTab: false,
    images: {
      on: "/images/general/pages/AllOn.svg",
      off: "/images/general/pages/AllOff.svg",
    },
    order: 104,
  },
  {
    path: "weeks",
    label: "Weeks",
    code: "CND",
    images: {
      on: "/images/general/pages/resortOn.png",
      off: "/images/general/pages/ResortsOff.svg",
    },
    order: 105,
  },
  {
    path: "resorts",
    label: "Resorts",
    code: "RST",
    images: {
      on: "/images/general/pages/resortOn.png",
      off: "/images/general/pages/ResortsOff.svg",
    },
    order: 106,
  },
  {
    path: "holidaystays",
    label: "Vacation Rentals",
    code: "WEK",
    images: {
      on: "/images/general/pages/WeeksOn.png",
      off: "/images/general/pages/WeeksOff.png",
    },
    order: 107,
  },
  {
    path: "cruises",
    label: "cruises",
    code: "CRU",
    images: {
      on: "/images/general/pages/CruiseOn.svg",
      off: "/images/general/pages/CruiseOff.svg",
    },
    order: 108,
  },
  {
    path: "flight",
    label: "flight",
    code: "FLT",
    images: {
      on: "/images/general/pages/PlaneOn.svg",
      off: "/images/general/pages/PlaneOff.svg",
    },
    order: 109,
  },
  {
    path: "cars",
    label: "cars",
    code: "CAR",
    images: {
      on: "/images/general/pages/CarOn.svg",
      off: "/images/general/pages/CarOff.svg",
    },
    order: 110,
  },
  {
    path: "transfers",
    label: "transfers",
    code: "TRA",
    images: {
      on: "/images/general/pages/TransfersOn.svg",
      off: "/images/general/pages/TransfersOff.svg",
    },
    order: 111,
  },
  {
    path: "activities",
    label: "activities",
    code: "ACT",
    images: {
      on: "/images/general/pages/ActivitiesOn.svg",
      off: "/images/general/pages/ActivitiesOff.svg",
    },
    order: 112,
  },
  {
    path: "https://happitrips.com",
    label: "Trips",
    code: "happitrips",
    images: {
      on: "/images/general/pages/HappiTripsOn.svg",
      off: "/images/general/pages/HappiTripsOff.svg",
    },
    order: 112,
  },
  {
    path: "method:dailydeals",
    label: "Daily Deals",
    code: "dailydeals",
    images: {
      on: "/images/general/pages/DailyDealsOn.svg",
      off: "/images/general/pages/DailyDealsOff.svg",
    },
    order: 114,
  },
];

export const productsExcluded = ["tra"];
