import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const Title = styled(Col)`
  text-align: left;
  padding-top: 0rem;
  padding-left: 2rem;
  /* background-image: url(/images/wallet/PaymentMethod1.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 25px; */
  font-size: 17px;
  font-weight: 600;
  color: #45486d;
`;
export const TitleRow = styled(Row)`
  padding: 0 3rem;
  padding-top: 1rem;
`;

export const Container = styled.div`
  display: ${({ theme }: any) => (theme.show ? "block" : "none")};
  @media only screen and (max-width: 1199px) {
    flex-direction: column;
  }
  /* min-height: 150px; */
  padding: 0rem 1rem;
`;

export const PaymentMethodImageContainer = styled.div`
  margin-top: 0.5rem;
  padding-left: 2rem;
  padding-right: 1rem;
`;
export const PaymentMethodImage = styled.img`
  content: url(/images/paymentMethods/CardsInvoice.svg);
`;
export const ColHalf = styled(Col)``;

export const ColHalfRight = styled(Col)`
  display: flex;
  align-items: center;
`;
export const ImgPaymentMethod = styled.img`
  width: 3.1rem;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(24deg) brightness(90%)
    contrast(103%);
`;
