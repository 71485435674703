import axios from "axios";

export const DISCOUNT_CARD_BASE_HLT: number = 1.1;

export const getPaymentCardByUser = async (
  product_type: Wallet_Product_Type,
  currency: string
): Promise<{
  paymentMethods: Wallet_Personal_API_PaymentMethods_Type | null;
  walletOptions: Wallet_Personal_API_walletOptions | null;
}> => {
  try {
    const response = await axios.post(`/api/booking/getpaymentcardbyuser`, {
      product_type,
      currency,
    });

    const isArray: boolean = Array.isArray(response?.data?.data?.data);
    const arrayLenght: number = isArray
      ? response?.data?.data?.data?.length
      : 0;
    return {
      paymentMethods: isArray ? response?.data?.data?.data[0] || null : null,
      walletOptions:
        arrayLenght > 1
          ? response?.data?.data?.data[1]?.walletOptions || ""
          : "",
    };
  } catch (error) {
    console.info(error);
    return {
      paymentMethods: null,
      walletOptions: null,
    };
  }
};
export const showWalletComponent = (
  walletData: Wallet_Personal_API_response,
  isLogged: boolean
) => {
  const show: boolean =
    isLogged &&
    (Number(walletData?.paymentMethods?.certificate_details?.active_card || 0) >
      0 ||
      Number(
        walletData?.paymentMethods?.discountcard_details?.active_card || 0
      ) > 0)
      ? true
      : false;
  return show;
};
export const getMountCertificateCashDiscount = (
  walletPersonalSelected: Wallet_Personal_Current_Selected_Info | null
) => {
  const disCount: number = Number(
    walletPersonalSelected?.payment_card_redemption_price ||
      walletPersonalSelected?.payment_card_purchase_price ||
      0
  );
  return disCount < 0 ? 0 : disCount;
};
export const contructInfoPersonalWalletObject = (
  obj: Wallet_Personal_API_Certificate_Data_Type,
  positionCard: number
) => {
  const infoObject: Wallet_Personal_Current_Selected_Info = {
    card_code: obj?.card_code || "",
    card_status: obj?.card_status || "",
    currency_code: obj?.currency_code || "",
    currency_name: obj?.currency_name || "",
    expiry_time: obj?.expiry_time || "",
    mst_type_code: obj?.mst_type_code || "",
    mst_type_id: obj?.mst_type_id || 0,
    mst_type_name: obj?.mst_type_name || "",
    payment_card_description: obj?.payment_card_description || "",
    payment_card_id: obj?.payment_card_id || 0,
    payment_card_image: obj?.payment_card_image || "",
    payment_card_name: obj?.payment_card_name || "",
    payment_card_purchase_price: obj?.payment_card_purchase_price || "",
    payment_card_redemption_price: obj?.payment_card_redemption_price || "",
    payment_card_thumbnail_image: obj?.payment_card_thumbnail_image || "",
    payment_card_type: obj?.payment_card_type || 0,
    pro_code: obj?.pro_code || "",
    pro_name: obj?.pro_name || "",
    status: obj?.status || "",
    positionSelectedCard: positionCard || 0,
    roeRate: obj?.roeRate || 1,
  };
  return infoObject;
};
export const fixedNumber = (number: number) => {
  return Number(number % 1 > 0 ? number.toFixed(2) : number.toFixed(0));
};
export const fixedNumberOneDecimal = (number: number) => {
  return Number(number % 1 > 0 ? number.toFixed(1) : number.toFixed(0));
};
export const WALLET_HLT_CERTIFICATE_perNightPrice = (
  walletPersonalSelected: Wallet_Personal_Current_Selected_Info | null,
  noOfNights: number,
  finalPrice: number
) => {
  let nextFinalPrice: number = finalPrice;
  const discount: number = fixedNumber(
    getMountCertificateCashDiscount(walletPersonalSelected) / noOfNights
  );
  const tmpNextFinalPric: number = nextFinalPrice - fixedNumber(discount);
  nextFinalPrice = Math.max(fixedNumber(tmpNextFinalPric), 0);
  return nextFinalPrice < 0 ? 0 : nextFinalPrice;
};
export const WALLET_HLT_CERTIFICATE_TOTAL = (
  walletPersonalSelected: Wallet_Personal_Current_Selected_Info | null,
  finalPrice: number
) => {
  let nextFinalPrice: number = finalPrice;
  const discount: number = fixedNumber(
    getMountCertificateCashDiscount(walletPersonalSelected)
  );
  nextFinalPrice = Math.max(fixedNumber(nextFinalPrice - discount), 0);

  return nextFinalPrice < 0 ? 0 : nextFinalPrice;
};
export const WALLET_HTL_DIS_CARD_IS_AVAILABLE_B2B = (
  price_type: string,
  markup: number
) => {
  let isAvailableb2bPrice: boolean = false;
  if (price_type == "B2B" && markup) {
    isAvailableb2bPrice = true;
  }
  return isAvailableb2bPrice;
};
export const WALLET_CALCULATE_SUPPLIER_PRICE = (
  inclusive: number,
  markupPrice: number,
  pertentageIncreaseCost: number
) => {
  const supplier_cost: number = fixedNumber(inclusive - markupPrice);
  const supplier_cost_percentege: number = fixedNumber(
    supplier_cost * pertentageIncreaseCost
  );
  const supplier_final_price: number = fixedNumber(
    supplier_cost + supplier_cost_percentege
  );
  return supplier_final_price < 0 ? 0 : supplier_final_price;
};
export const WALLET_HLT_DIS_CARD_MAX_DISCOUNT = (
  walletPersonalSelected: Wallet_Personal_Current_Selected_Info | null,
  priceJSON: PriceType,
  noOfNights: number,
  maxRedemtionEvaluate?: number
) => {
  /* VARS */
  let current_max_discount_card: number = 0;
  const current_redemption: number =
    maxRedemtionEvaluate == undefined
      ? getMountCertificateCashDiscount(walletPersonalSelected)
      : maxRedemtionEvaluate;
  const net_rate_before_markup: number = Number(
    priceJSON?.net_rate_before_markup || 0
  );
  const room_tax_before_markup: number = Number(
    priceJSON?.tax_before_markup || priceJSON?.room_tax_before_markup || 0
  );

  /* Expedia */
  const b2c_price: number = fixedNumber(
    (Number(priceJSON?.strike_price || 0) +
      Number(priceJSON?.strike_tax || 0)) *
      noOfNights
  );
  const suppllier_price: number = fixedNumber(
    net_rate_before_markup + room_tax_before_markup
  );
  const B2B_PRICE: number = fixedNumber(Number(priceJSON?.inclusive || 0));
  const MIN_PRICE: number = fixedNumber(
    suppllier_price * DISCOUNT_CARD_BASE_HLT
  );
  const MAX_DISCOUNT: number = B2B_PRICE - MIN_PRICE;

  if (MAX_DISCOUNT > current_redemption) {
    current_max_discount_card = current_redemption;
  } else {
    current_max_discount_card = MAX_DISCOUNT;
  }
  b2c_price ? null : null;
  if (!net_rate_before_markup) {
    return 0;
  }

  return current_max_discount_card < 0 ? 0 : current_max_discount_card;
};
export const WALLET_HLT_DIS_CARD_TOTAL = (
  walletPersonalSelected: Wallet_Personal_Current_Selected_Info | null,
  priceJSON: PriceType,
  noOfNights: number,
  withTax: boolean,
  maxRedemtionEvaluate?: number
) => {
  /* VARS */
  let totalPreview: number = 0;
  let current_max_discount_card: number = WALLET_HLT_DIS_CARD_MAX_DISCOUNT(
    walletPersonalSelected,
    priceJSON,
    noOfNights,
    maxRedemtionEvaluate
  );

  /* CALCULATIONS */
  if (withTax) {
    totalPreview = fixedNumber(
      Number(priceJSON?.inclusive) - Number(current_max_discount_card)
    );
  } else {
    totalPreview = fixedNumber(
      Number(priceJSON?.exclusive) +
        Number(priceJSON?.tax) -
        Number(current_max_discount_card)
    );
  }

  return totalPreview < 0 ? 0 : totalPreview;
};
export const WALLET_HLT_DIS_CARD_perNightPrice = (
  walletPersonalSelected: Wallet_Personal_Current_Selected_Info | null,
  noOfNights: number,
  priceJSON: PriceType,
  withTax: boolean
) => {
  const discountCardTotalInclusive: number = WALLET_HLT_DIS_CARD_TOTAL(
    walletPersonalSelected,
    priceJSON,
    noOfNights,
    withTax
  );
  const pricePerDay: number = fixedNumber(
    discountCardTotalInclusive / noOfNights
  );
  return pricePerDay < 0 ? 0 : pricePerDay;
};
export const IS_PERSONAL_WALLET_CERTIFICATE_ON = (
  currentOptionSelected: Wallet_Personal_Current_Selected_Type,
  walletPersonalSelected: Wallet_Personal_Current_Selected_Info | null
) => {
  const show: boolean =
    currentOptionSelected == "certificates" && walletPersonalSelected
      ? true
      : false;

  return show;
};
export const IS_PERSONAL_WALLET_DISCOUNT_CARD_ON = (
  currentOptionSelected: Wallet_Personal_Current_Selected_Type,
  walletPersonalSelected: Wallet_Personal_Current_Selected_Info | null,
  price_type: string,
  markup: number
) => {
  const show: boolean =
    currentOptionSelected == "discount_cards" &&
    walletPersonalSelected &&
    WALLET_HTL_DIS_CARD_IS_AVAILABLE_B2B(price_type, markup)
      ? true
      : false;

  return show;
};
export const IS_PERSONAL_WALLET_ON = (
  currentOptionSelected: Wallet_Personal_Current_Selected_Type,
  walletPersonalSelected: Wallet_Personal_Current_Selected_Info | null,
  price_type: string,
  markup: number
) => {
  const show: boolean =
    (currentOptionSelected == "certificates" && walletPersonalSelected) ||
    (currentOptionSelected == "discount_cards" &&
      walletPersonalSelected &&
      WALLET_HTL_DIS_CARD_IS_AVAILABLE_B2B(price_type, markup))
      ? true
      : false;

  return show;
};
export const CALCULATE_MARKUP = (
  b2b_net_rate: number,
  b2b_tax: number,
  price_type: string,
  supplier_price: SupplierPriceType[],
  supplier_code: string
) => {
  let markup: number = 0;
  const b2b_total = fixedNumber(b2b_net_rate + b2b_tax);
  let supplier_total: number = 0;

  supplier_price?.map((obj) => {
    if (obj?.price_type == price_type && obj?.supplier_code == supplier_code) {
      supplier_total = fixedNumber(
        Number(obj?.net_rate || 0) + Number(obj?.room_tax || 0)
      );
    }
  });
  if (supplier_total) {
    markup = Math.max(fixedNumber(b2b_total - supplier_total), 0);
  }
  return markup < 0 ? 0 : markup;
};
export const CALCULATE_POINTS_DISCOUNT_CARD = (
  discountPrice: number,
  roeRate: number
) => {
  const POINTS_PER_DOLLAR: number = roeRate || 1;
  const total: number = fixedNumber(discountPrice / POINTS_PER_DOLLAR);

  return total < 0 ? 0 : total;
};
export const calculate_net_rate_before_markup = (
  currentRoom: RoomType | null | undefined
) => {
  let newNet_rate_before_markup: number = 0;
  const room: SupplierPriceType[] = currentRoom?.supplier_price || [];
  room?.map((obj) => {
    if (
      obj?.supplier_code == currentRoom?.supplier_code &&
      obj?.price_type == "B2B"
    ) {
      newNet_rate_before_markup = Number(obj?.exclusive || 0);
    }
  });
  return newNet_rate_before_markup;
};
export const calculate_room_tax_before_markup = (
  currentRoom: RoomType | null | undefined
) => {
  let newRroom_tax_before_markup: number = 0;
  const room: SupplierPriceType[] = currentRoom?.supplier_price || [];
  room?.map((obj) => {
    if (
      obj?.supplier_code == currentRoom?.supplier_code &&
      obj?.price_type == "B2B"
    ) {
      newRroom_tax_before_markup = Number(obj?.room_tax || 0);
    }
  });
  return newRroom_tax_before_markup;
};
export const getDiscountCardInformationRoomSelected = (
  allDiscountCardRoomApplied: PersonalWalletSummaryRoomsType[],
  roomTypeId: string
) => {
  return allDiscountCardRoomApplied.find((x) => x.roomTypeId == roomTypeId);
};
export const getAllDiscountCardAmountUsedInRooms = (
  allDiscountCardRoomApplied: PersonalWalletSummaryRoomsType[]
) => {
  let totalAmount: number = 0;
  allDiscountCardRoomApplied?.map((obj) => {
    totalAmount += obj?.amountUseInRoom || 0;
  });
  return totalAmount < 0 ? 0 : totalAmount;
};
export const getProductTypeByPathName = (
  pathname: string,
  returnSeparatePath?: boolean
) => {
  let product_type: Wallet_Product_Type = "";
  const hotel_paths: string[] = [
    "/",
    "/home",
    "/search-detail",
    "/hotel-details",
    "/holidaystays",
    "/holidaystays/search-detail",
    "/holidaystays/hotel-details",
  ];

  const resorts_paths: string[] = [
    "/resorts",
    "/home/resorts",
    "/resorts/search-details",
    "/resorts/details",
  ];
  const stays_paths: string[] = [
    "/stays",
    "/home/stays",
    "/stays/search-details",
    "/stays/details",
  ];

  const flight_paths: string[] = [
    "/home/flight",
    "/flight/search-details",
    "/flight/checkout",
  ];

  const transfers_paths: string[] = [
    "/home/transfers",
    "/transfers/search-details",
    "/transfers/details",
  ];

  const cars_paths: string[] = [
    "/home/cars",
    "/cars/search-details",
    "/cars/details",
  ];

  const activities_paths: string[] = [
    "/activities",
    "/home/activities",
    "/activities/search-details",
    "/activities/details",
  ];

  const cruises_paths: string[] = ["/cruises"];

  if (hotel_paths.includes(pathname)) {
    product_type = "HLT";
    if (returnSeparatePath && pathname.indexOf("/holidaystays") > -1) {
      product_type = "VR";
    }
  }
  if (resorts_paths.includes(pathname)) {
    product_type = "RST";
  }
  if (flight_paths.includes(pathname)) {
    product_type = "FLT";
  }
  if (transfers_paths.includes(pathname)) {
    product_type = "TRF";
  }
  if (cars_paths.includes(pathname)) {
    product_type = "CAR";
  }
  if (activities_paths.includes(pathname)) {
    product_type = "ACT";
  }
  if (cruises_paths.includes(pathname)) {
    product_type = "CRU";
  }
  if (stays_paths.includes(pathname)) {
    product_type = "STY";
  }

  return product_type;
};
export const getProductTypeByAllPathNames = (
  pathname: string,
  includeCheckOutSuccess?: boolean
) => {
  let product_type: Wallet_Product_Type = "";

  let hotel_paths: string[] = [
    "/",
    "/home",
    "/search-detail",
    "/hotel-details",
    "/hotel-checkout",
    "/holidaystays",
    "/holidaystays/search-detail",
    "/holidaystays/hotel-details",
    "/holidaystays/hotel-checkout",
  ];

  let resorts_paths: string[] = [
    "/resorts",
    "/home/resorts",
    "/resorts/search-details",
    "/resorts/details",
    "/resorts/checkout",
  ];
  let stays_paths: string[] = [
    "/stays",
    "/home/stays",
    "/stays/search-details",
    "/stays/details",
    "/stays/checkout",
  ];

  let flight_paths: string[] = [
    "/flight",
    "/home/flight",
    "/flight/search-details",
    "/flight/checkout",
  ];

  let transfers_paths: string[] = [
    "/transfers",
    "/home/transfers",
    "/transfers/search-details",
    "/transfers/details",
    "/transfers/checkout",
  ];

  let cars_paths: string[] = [
    "/cars",
    "/home/cars",
    "/cars/search-details",
    "/cars/details",
    "/cars/checkout",
  ];

  let activities_paths: string[] = [
    "/activities",
    "/home/activities",
    "/activities/search-details",
    "/activities/details",
    "/activities/checkout",
  ];

  let cruises_paths: string[] = ["/cruises"];

  const hotel_paths_checkoutSuccess: string[] = ["/checkout-success"];
  const resorts_paths_checkoutSuccess: string[] = ["/resorts/checkout-success"];
  const flight_paths_checkoutSuccess: string[] = ["/flight/checkout-success"];
  const cars_paths_checkoutSuccess: string[] = ["/cars/checkout-success"];
  const activities_paths_checkoutSuccess: string[] = [
    "/activities/checkout-success",
  ];
  const transfers_paths_checkoutSuccess: string[] = [
    "/transfers/checkout-success",
  ];
  const stays_paths_checkoutSuccess: string[] = ["/stays/checkout-success"];

  if (includeCheckOutSuccess) {
    hotel_paths_checkoutSuccess?.map((obj: string) => {
      hotel_paths.push(obj);
    });
    resorts_paths_checkoutSuccess?.map((obj: string) => {
      resorts_paths.push(obj);
    });
    flight_paths_checkoutSuccess?.map((obj: string) => {
      flight_paths.push(obj);
    });
    transfers_paths_checkoutSuccess?.map((obj: string) => {
      transfers_paths.push(obj);
    });
    cars_paths_checkoutSuccess?.map((obj: string) => {
      cars_paths.push(obj);
    });
    activities_paths_checkoutSuccess?.map((obj: string) => {
      activities_paths.push(obj);
    });

    stays_paths_checkoutSuccess?.map((obj: string) => {
      stays_paths.push(obj);
    });
  }

  if (hotel_paths.includes(pathname)) {
    product_type = "HLT";
  }
  if (resorts_paths.includes(pathname)) {
    product_type = "RST";
  }
  if (flight_paths.includes(pathname)) {
    product_type = "FLT";
  }
  if (transfers_paths.includes(pathname)) {
    product_type = "TRF";
  }
  if (cars_paths.includes(pathname)) {
    product_type = "CAR";
  }
  if (activities_paths.includes(pathname)) {
    product_type = "ACT";
  }
  if (cruises_paths.includes(pathname)) {
    product_type = "CRU";
  }
  if (stays_paths.includes(pathname)) {
    product_type = "STY";
  }
  return product_type;
};
export const add3Dots = (word: string, limit: number) => {
  var dots = "...";
  if (word.length > limit) {
    // you can also use substr instead of substring
    word = word.substring(0, limit) + dots;
  }
  return word;
};
export const findRealPositionIndexRoomByRoomTypeId = (
  room: MultipleRoomType | undefined,
  selection: CurrentSelectionType
) => {
  let roomIndex: number = 0;
  if (room) {
    if (room.room == selection.roomNumber) {
      const room_groups = room.room_groups || [];
      if (Array.isArray(room_groups) && room_groups.length > 0) {
        room_groups?.map((groupObj: RoomGroupsType, groupPosition: number) => {
          const room_type = groupObj.room_type || [];
          if (Array.isArray(room_type) && room_type?.length > 0) {
            room_type?.map((roomInfo) => {
              if (roomInfo.id == selection.roomTypeId) {
                roomIndex = groupPosition;
              }
            });
          }
        });
      }
    }
  }
  return roomIndex;
};
export const discountCardRoomApplied = (
  currentSelecctions: CurrentSelectionType[] | undefined,
  data: ResultMultipleRoomType | undefined,
  currentOptionSelected: Wallet_Personal_Current_Selected_Type,
  walletPersonalSelected: Wallet_Personal_Current_Selected_Info | null,
  currentDiscountCardAmount: number
) => {
  const currentSelecctionsLenght: number = Number(
    currentSelecctions?.length || 0
  );
  let allDiscountCardRoomApplied: PersonalWalletSummaryRoomsType[] = [];
  if (currentSelecctionsLenght && currentSelecctions) {
    currentSelecctions.map((selection) => {
      const room = data?.rooms.find((f) => f.room === selection.roomNumber);
      let groupRoom = room?.room_groups[selection.groupRoomIndex];
      let roomType = groupRoom?.room_type.find((f) => {
        return f.id == selection.roomTypeId;
      });

      if (!roomType || roomType === undefined) {
        const positionByTypeID: number = findRealPositionIndexRoomByRoomTypeId(
          room,
          selection
        );

        groupRoom = room?.room_groups[positionByTypeID];
        roomType = groupRoom?.room_type.find((f) => {
          return f.id == selection.roomTypeId;
        });
      }
      const calulateMarkup: number = CALCULATE_MARKUP(
        Number(roomType?.price?.net_rate || 0),
        Number(roomType?.price?.room_tax || 0),
        roomType?.price?.price_type || "",
        roomType?.supplier_price || [],
        roomType?.supplier_code || ""
      );

      const WALLET_DISCOUNT_CARD_ON: boolean =
        IS_PERSONAL_WALLET_DISCOUNT_CARD_ON(
          currentOptionSelected,
          walletPersonalSelected,
          roomType?.price?.price_type || "",
          calulateMarkup
        );

      const newPriceObj: any = {
        inclusive: Number(roomType?.price?.inclusive || 0),
        exclusive: Number(roomType?.price?.exclusive || 0),
        tax: Number(roomType?.price?.room_tax || 0),
        strike_price: Number(roomType?.price?.strike_price || 0),
        strike_tax: Number(roomType?.price?.strike_tax || 0),
        net_rate: Number(roomType?.price?.net_rate || 0),
        markup: calulateMarkup,
        net_rate_before_markup: Number(
          roomType?.price?.net_rate_before_markup || 0
        ),
        room_tax_before_markup: Number(
          roomType?.price?.room_tax_before_markup || 0
        ),
      };

      if (WALLET_DISCOUNT_CARD_ON) {
        if (allDiscountCardRoomApplied.length == 0) {
          const amountUseInRoom: number = CALCULATE_POINTS_DISCOUNT_CARD(
            fixedNumber(
              WALLET_HLT_DIS_CARD_MAX_DISCOUNT(
                walletPersonalSelected,
                newPriceObj,
                Number(roomType?.no_of_nights || 1)
              )
            ),
            Number(walletPersonalSelected?.roeRate || 1)
          );
          const availableToUseAmount: number =
            currentDiscountCardAmount - amountUseInRoom;
          allDiscountCardRoomApplied.push({
            wallet_id: walletPersonalSelected?.card_code || "",
            currentAmount: currentDiscountCardAmount,
            amountUseInRoom: amountUseInRoom,
            availableToUseAmount:
              availableToUseAmount > 0 ? availableToUseAmount : 0,
            roomNumber: selection.roomNumber,
            groupRoomIndex: selection.groupRoomIndex,
            roomTypeId: selection.roomTypeId,
          });
        } else {
          const lenghtArray: number = allDiscountCardRoomApplied?.length - 1;
          let dataArray: PersonalWalletSummaryRoomsType | null =
            allDiscountCardRoomApplied[lenghtArray] || null;
          if (dataArray) {
            const amountUseInRoom: number = CALCULATE_POINTS_DISCOUNT_CARD(
              fixedNumber(
                WALLET_HLT_DIS_CARD_MAX_DISCOUNT(
                  walletPersonalSelected,
                  newPriceObj,
                  Number(roomType?.no_of_nights || 1)
                )
              ),
              Number(walletPersonalSelected?.roeRate || 1)
            );
            const lastAvailableToUseAmount: number =
              dataArray?.availableToUseAmount || 0;
            const availableToUseAmount: number =
              lastAvailableToUseAmount - amountUseInRoom;

            allDiscountCardRoomApplied.push({
              wallet_id: walletPersonalSelected?.card_code || "",
              currentAmount: lastAvailableToUseAmount,
              amountUseInRoom:
                amountUseInRoom <= lastAvailableToUseAmount
                  ? amountUseInRoom
                  : lastAvailableToUseAmount,
              availableToUseAmount:
                availableToUseAmount > 0 ? availableToUseAmount : 0,
              roomNumber: selection.roomNumber,
              groupRoomIndex: selection.groupRoomIndex,
              roomTypeId: selection.roomTypeId,
            });
          }
        }
      }
    });
  }
  return allDiscountCardRoomApplied;
};
