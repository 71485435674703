import axios from "axios";

export const applyResortsCouponCode = async (
  checkoutId: string | string[],
  productType: Wallet_Product_Type,
  action: cashCardRedemption_ACT_TRF_AppliType
) => {
  const response = await axios.post("/api/booking/cashCardRedemption_ACT_TRF", {
    checkoutId: checkoutId,
    productType: productType,
    action: action,
  });
  console.log("/api/booking/cashCardRedemption_ACT_TRF", {
    response: response,
  });
  return response.data;
};
